import {
	WizzardInterface,
	WizzardState, WizzardType,
} from '@/theconcept/wizzard/base/wizzard.interface';
import i18n from '@/i18n';
import {
	StepSummaryDataInterface,
	WizzardSummary,
} from '@/theconcept/wizzard/base/wizzard_summary';
import useTranslation from "@/composable/translation/useTranslation";

export interface WizzardStepStatus {
	step: number,
	current: boolean,
	title: string,
	description: string,
}

export abstract class AbstractWizzard implements WizzardInterface {
	name: string | undefined;
	wizzardSummary: WizzardSummary | undefined;
	_state: WizzardState;
	currentStep: number;
	_estimatedCosts: number | undefined;
	_isNextStepEnabled = true;
	private _autoCloseWizzardAfterLastStep = false;

	abstract getStepsPath(): string;

	abstract getType(): WizzardType;

	protected constructor() {
		this._state = WizzardState.NEW;
		this.currentStep = 0;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	validateStep(step: number): boolean {
		return true;
	}

	getWizzardBasePath(): string {
		return 'base';
	}

	getWizzardSummaryBasePath(): string {
		return 'base/summary';
	}

	validateNextStep(nextStep: number|any = undefined): boolean {
		let isValid = true;
		if (!nextStep) {
			nextStep = this.getCurrentStep() + 1;
		}
		const stepMap = this.getStepComponentMap();
		const stepKeys = Object.keys(stepMap);
		stepKeys.forEach((value, step) => {
			if (isValid) {
				const actualStep = step + 1;
				if (actualStep < nextStep) {
					isValid = this.validateStep(actualStep);
				}
			}
		});

		return isValid;
	}

	get state(): WizzardState {
		return this._state;
	}

	set state(value: WizzardState) {
		this._state = value;
	}

	get estimatedCosts(): number | undefined {
		return this._estimatedCosts;
	}

	set estimatedCosts(value: number | undefined) {
		this._estimatedCosts = value;
	}

	get isNextStepEnabled(): boolean {
		return this._isNextStepEnabled;
	}

	set isNextStepEnabled(value: boolean) {
		this._isNextStepEnabled = value;
	}

	getWizzardName(): string {
		if (this.name && this.name.length > 0) {
			return this.name;
		}

		return i18n.global.t('wizzard.' + this.getType() + '.title');
	}

	getWizzardSummaryComponent(): string {
		return 'WizzardSummaryComponent';
	}

	getStepsCount(): number {
		const stepMap = this.getStepComponentMap();
		const stepKeys = Object.keys(stepMap);
		return stepKeys.length;
	}

	getCurrentStep(): number {
		return this.currentStep;
	}

	//https://tailwindcomponents.com/gradient-generator/
	getWizzardColor(): string {
		return 'bg-tc-blue';
	}

	getWizzardPrimaryTextColor(): string {
		return 'text-tc-blue';
	}

	getWizzardDefaultStepTextColor(): string {
		return 'text-gray-400';
	}

	isLastStepSummary(): boolean {
		return this.getCurrentStep() === this.getStepsCount();
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	getWizzardSummaryDataByStep(step: number): StepSummaryDataInterface|undefined {
		return;
	}

	goToNextStep(): number | undefined {
		if (this.getCurrentStep() < this.getStepsCount() && this.validateNextStep()) {
			this.addSummaryByCurrentStep();
			this.currentStep = this.getCurrentStep() + 1;
			return this.getCurrentStep();
		}
		return;
	}

	goToStep(step: number): number | undefined {
		if (step <= this.getStepsCount() && this.validateNextStep(step)) {
			this.addSummaryByCurrentStep();
			this.currentStep = step;
			return this.getCurrentStep();
		}
		return;
	}

	goToSummaryStep(): undefined {
		if (this.isLastStep()) {
			this.addSummaryByCurrentStep();
		}
		return;
	}

	private addSummaryByCurrentStep() {
		const summaryStepData = this.getWizzardSummaryDataByStep(this.getCurrentStep());
		if (summaryStepData) {
			this.getWizzardSummary().addSummaryData(summaryStepData);
		}
	}

	goToPreviousStep(): number | undefined {
		if (this.getCurrentStep() > 0) {
			this.currentStep = this.getCurrentStep() - 1;
			return this.getCurrentStep();
		}
		return;
	}

	getStepComponentMap(): Record<number, string> {
		return {};
	}

	getCurrentStepComponent(): string | undefined {
		if (this.getCurrentStep() < 1) {
			return undefined;
		}
		const stepMap = this.getStepComponentMap();
		const stepKeys = Object.keys(stepMap);
		if (stepKeys.length < 1) {
			return undefined;
		}
		return stepMap[this.getCurrentStep()];
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	getStepTitle(step: number): string {
		return '';
	}

	getStepsStatusInfo(): Record<number, WizzardStepStatus> {
		return {};
	}

	getStepProgressInPercent(): number {
		const currentStep = this.getCurrentStep();
		const maxSteps = this.getStepsCount();
		return Math.round((currentStep / maxSteps) * 100);
	}

	getStepStatusInfo(step: number): WizzardStepStatus | undefined {
		if (this.getStepsStatusInfo()[step]) {
			return this.getStepsStatusInfo()[step];
		}
		return undefined;
	}

	isPreviousStepAllowed(): boolean {
		return this.getCurrentStep() > 1;
	}

	isNextStepAllowed(): boolean {
		if (!this.isNextStepEnabled) {
			return false;
		}
		return this.getCurrentStep() < this.getStepsCount();

	}

	isStepAllowed(step: number): boolean {
		return step <= this.getStepsCount();
	}

	isFirstStep(): boolean {
		return this.getCurrentStep() === 1;
	}

	isLastStep(): boolean {
		return this.getCurrentStep() === this.getStepsCount();
	}

	isSimulateAble(): boolean {
		return false;
	}

	isWizzardComplete(): boolean {
		return this._state === WizzardState.READY;
	}

	hasSummary(): boolean {
		return true;
	}

	getWizzardSummary(): WizzardSummary {
		if (!this.wizzardSummary) {
			this.wizzardSummary = new WizzardSummary();
		}
		return this.wizzardSummary;
	}

	isWizzardDone(): boolean {
		return this._state === WizzardState.DONE;
	}

	get autoCloseWizzardAfterLastStep(): boolean {
		return this._autoCloseWizzardAfterLastStep;
	}

	set autoCloseWizzardAfterLastStep(value: boolean) {
		this._autoCloseWizzardAfterLastStep = value;
	}

	markWizzardAsDone() {
		this._state = WizzardState.DONE;
	}

	getTranslation(key: string): string {
		return useTranslation().t(key);
	}

	protected getReferenceWithoutSpecialChars(value: string): string {
		value = value.toLowerCase();
		value = value.replace(/\s+/g, '');
		value = value.replace(/[^a-zA-Z0-9]/g, '');
		value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
		return value.replace(/[\u{1F300}-\u{1F5FF}]/gu, '');
	}

	serialize(): string {
		return JSON.stringify(this);
	}

	serializeForApiRequest(): string {
		return this.serialize();
	}
}