export class RouteChecker {
    private readonly activeRoutes: string[];

    constructor(routes: string[]) {
        this.activeRoutes = routes;
    }

    public isRouteActive(currentRoute: string): boolean {
        if (!currentRoute) {
            return false;
        }
        console.log(this.activeRoutes, currentRoute)
        return this.activeRoutes.includes(currentRoute);
    }
}