import {computed} from 'vue';
import store from "@/store";

export interface ScriptInterface {
	src: string;
	type?: string;
	arguments?: any;
	content?: string;
}

export default function useCustomStore() {
	const getPageReloaded = computed(() => store.getters['custom/getPageReload']);
	const getAdBlocker = computed(() => store.getters['custom/getAdBlocker']);
	const isAdBlockerCheckRunning = computed(() => store.getters['custom/getAdBlockerCheckRunning']);
	const getAdBlockerCheckUrl = computed(() => store.getters['custom/getAdBlockerCheckUrl']);
	const getCookies = computed(() => store.getters['custom/getCookies']);
	const getCookieDetails = computed(() => store.getters['custom/getCookieDetails']);
	const getLanguageLocale = computed(() => store.getters['custom/getLanguageLocale']);
	const getHighlightColor = computed(() => store.getters['custom/getHighlightColor']);
	const isScrolled = computed(() => store.getters['custom/isScrolled']);
	const isCookieScriptsDeleted = computed(() => store.getters['custom/isCookieScriptsDeleted']);

	function isFullCookieConsentAllowed(): boolean
	{
		return getCookieDetails.value.all === true;
	}

	function isMinimalCookieConsentAllowed(): boolean
	{
		return getCookieDetails.value.minimal === true;
	}

	// Function to check if script is already appended to the DOM
	function isLinkAlreadyAppended(script: string) {
		const scripts = Array.from(document.getElementsByTagName('script'));
		let result = false;
		scripts.forEach((s) => (s.src == script ? (result = true) : null));
		return result;
	}

	// Function that creates our script element. Also adds attributes if that
	// parameter is filled with an object
	function createScriptElement(script: string, attributes = {}, scriptContent = '', scriptType = '') {
		const el = document.createElement('script');
		if (script.length > 0) {
			el.src = script;
		}
		let key: any;
		let value: any;
		for ([key, value] of Object.entries(attributes)) {
			el.setAttribute(key, value);
		}
		if (scriptContent.length > 0) {
			el.text = scriptContent;
		}
		if (scriptType.length > 0) {
			el.type = scriptType;
		}
		return el;
	}

	// Function that appends script to the Head. Could also be adjusted to prepend
	// it before the closing </body> if you want to.
	function appendScriptElement(el) {
		if (!isLinkAlreadyAppended(el.src)) {
			document.getElementsByTagName('head')[0].appendChild(el)
		}
	}

	function normalizeSrc(src: string): string {
		return src.replace(/^(https?:\/\/|\/\/)/, '');
	}

	// Function to remove script elements based on the src in an array
	function removeScripts(arr: ScriptInterface[]): void {
		arr.forEach(script => {
			if (script.src) {
				removeScriptElement(script.src);
			}
		});
	}

	// Your existing removeScriptElement function
	function removeScriptElement(src: string): void {
		const normalizedSrc = normalizeSrc(src);
		const scripts = document.getElementsByTagName('script');

		for (let i = 0; i < scripts.length; i++) {
			const scriptSrc = normalizeSrc(scripts[i].src);
			if (scriptSrc == normalizedSrc) {
				scripts[i].parentNode?.removeChild(scripts[i]);
				break;
			}
		}
	}

	function setPageReloaded(value: boolean) {
		store.commit('custom/setPageReload', value)
	}

	function setAdBlocker(value: boolean) {
		store.commit('custom/setAdBlocker', value)
	}

	function setAdBlockerCheckRunning(value: boolean) {
		store.commit('custom/setAdBlockerCheckRunning', value)
	}

	function setAdBlockerCheckUrl(value: string) {
		store.commit('custom/setAdBlockerCheckUrl', value)
	}

	function setCookies(value: boolean) {
		store.commit('custom/setCookies', value)
	}

	function resetCookies() {
		store.commit('custom/setCookies', false)
	}

	function setCookieDetails(value) {
		store.commit('custom/setCookieDetails', value)
	}

	function setLanguageLocale(value: string) {
		store.commit('custom/setLanguageLocale', value)
	}

	function setHighlightColor(value: string) {
		store.commit('custom/setHighlightColor', value)
	}

	function setIsScrolled(value: boolean) {
		store.commit('custom/setIsScrolled', value)
	}

	function setIsCookieScriptsDeleted(value: boolean) {
		store.commit('custom/setCookieScriptsDeleted', value)
	}

	function getLanguageByNavigator(options = {}): string[]|undefined {
		const defaultOptions = {
			languageCodeOnly: false,
		};
		const opt = {
			...defaultOptions,
			...options,
		};
		const browserLocales =
			navigator.languages === undefined
				? [navigator.language]
				: navigator.languages;
		if (!browserLocales) {
			return undefined;
		}
		return browserLocales.map(locale => {
			const trimmedLocale = locale.trim();
			return opt.languageCodeOnly
				? trimmedLocale.split(/-|_/)[0]
				: trimmedLocale;
		});
	}

	function getDefaultBrowserLanguageCode(): string
	{
		let result: any = process.env.VUE_APP_I18N_LOCALE || 'en';

		const hostname = window.location.hostname;
		if (hostname.endsWith('.at')) {
			result = 'de';
		} else if (hostname.endsWith('.com')) {
			const arrLanguageMap = getLanguageByNavigator({languageCodeOnly: true});
			if (arrLanguageMap) {
				result = arrLanguageMap.shift();
			}
			// result = 'en';
		}

		return result;
	}

	return {
		getPageReloaded,
		getAdBlocker,
		getAdBlockerCheckUrl,
		getCookies,
		getCookieDetails,
		getLanguageLocale,
		isAdBlockerCheckRunning,
		setPageReloaded,
		setAdBlocker,
		setAdBlockerCheckRunning,
		setAdBlockerCheckUrl,
		setCookies,
		setCookieDetails,
		setLanguageLocale,
		getLanguageByNavigator,
		getDefaultBrowserLanguageCode,
		createScriptElement,
		appendScriptElement,
		isFullCookieConsentAllowed,
		isMinimalCookieConsentAllowed,
		getHighlightColor,
		isScrolled,
		setHighlightColor,
		setIsScrolled,
		resetCookies,
		removeScripts,
		removeScriptElement,
		setIsCookieScriptsDeleted,
		isCookieScriptsDeleted,
	}
}
