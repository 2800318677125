import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';

/* Theme variables */
import './assets/styles/index.css';

/* API */
import store from '@/store';
import createI18n from './i18n';

/* Other */
import VueKinesis from 'vue-kinesis';
import ApiService from "@/theconcept/api/base/api.service";

const app = createApp(App).
use(createI18n).
use(store).
use(router).
use(VueKinesis);

ApiService.init(process.env.VUE_APP_API_STABLE_URL);
ApiService.mount401Interceptor();

router.isReady().then(() => {
    app.mount('#app');
    // initializeEcho().then(() => {
    // 	app.mount('#app');
    // });
});