<template>
	<router-link v-if="cardButtonLinkActive" :to="cardButtonLink">
		<div class="transform transition duration-300 hover:scale-105 shadow-2xl card max-w-96 overflow-hidden my-4 hover:shadow-2xl">
			<figure class="relative max-h-48 overflow-hidden">
				<img v-if="pictureSrcActive" :src="pictureSrc" alt="Banner" :loading="imgLoadingType">
				<img v-if="pictureSrc2Active" :src="pictureSrc2" alt="Logo" class="logo absolute h-1/3" :loading="imgLoadingType">
				<div v-else class="logo absolute h-1/3"></div>
			</figure>
			<div class="bg-tc-bglight card-body px-4 py-6">
				<h2 class="card-title text-white">{{ cardTitle }}
					<span v-if="cardVersionInfo" class="ml-0 badge text-sm m-2 text-tc-bg">{{ cardVersionInfo }}</span>
					<span v-if="cardInfo" class="ml-0 badge text-sm m-2 bg-tc-yellow text-tc-bg">{{ cardInfo }}</span>
					<span v-if="cardLaterInfo" class="ml-0 badge text-sm m-2 bg-tc-orange text-tc-bg">{{ cardLaterInfo }}</span>
					<span v-if="cardNewInfo" class="ml-0 badge text-sm m-2 bg-white text-tc-bg">{{ cardNewInfo }}</span>
				</h2>
				<p class="text-white line-clamp-3 mb-2">{{ cardDescription }}</p>
				<a :href="cardButtonLink" class="card-actions">
					<button :class="['text-white btn w-full', btnColorApp]">{{ cardButton }}</button>
				</a>
			</div>
		</div>
	</router-link>
	<a v-else :href="cardButtonLink" class="card-actions">
		<div class="shadow-2xl card max-w-96 overflow-hidden my-4 hover:shadow-2xl">
			<figure class="relative max-h-48 overflow-hidden">
				<img v-if="pictureSrcActive" :src="pictureSrc" alt="Banner" :loading="imgLoadingType">
				<img v-if="pictureSrc2Active" :src="pictureSrc2" alt="Logo" class="logo absolute h-1/3" :loading="imgLoadingType">
			</figure>
			<div class="bg-tc-bglight card-body rounded-2xl overflow-hidden px-4 py-6">
				<h2 class="card-title text-white">{{ cardTitle }}
					<span v-if="cardNewInfo" class="ml-1 badge text-sm m-2 bg-white text-tc-bg">{{ cardNewInfo }}</span>
					<span v-if="cardVersionInfo" class="ml-0 badge text-sm m-2 text-tc-bg bg-tc-yellow ">{{ cardVersionInfo }}</span>
					<span v-if="cardInfo" class="ml-0 badge text-sm m-2 bg-tc-yellow text-tc-bg">{{ cardInfo }}</span>
					<span v-if="cardLaterInfo" class="ml-0 badge text-sm m-2 bg-tc-orange text-tc-bg">{{ cardLaterInfo }}</span>
				</h2>
				<p class="text-white h-fit line-clamp-3 mb-2">{{ cardDescription }}</p>
				<a :href="cardButtonLink" class="card-actions">
					<button :class="['text-white btn w-full', btnColorService]">{{ cardButton }}</button>
				</a>
			</div>
		</div>
	</a>
</template>

<script>
export default {
	name: "Card",
	props: {
		pictureSrc: {
			type: String,
			default: ""
		},
		pictureSrc2: {
			type: String,
			default: ""
		},
		pictureSrc2Active: {
			type: Boolean,
			default: true
		},
		pictureSrcActive: {
			type: Boolean,
			default: true
		},
		cardTitle: {
			type: String,
			default: ""
		},
		cardInfo: {
			type: String,
			default: ""
		},
		cardNewInfo: {
			type: String,
			default: ""
		},
		cardLaterInfo: {
			type: String,
			default: ""
		},
		cardVersionInfo: {
			type: String,
			default: ""
		},
		cardDescription: {
			type: String,
			default: ""
		},
		cardButton: {
			type: String,
			default: ""
		},
		cardButtonLink: {
			type: String,
			default: ""
		},
		cardButtonLinkActive: {
			type: Boolean,
			default: false
		},
		btnColorService: {
			type: String,
			default: "bg-tc-green w-full hover:bg-tc-green"
		},
		btnColorApp: {
			type: String,
			default: "bg-tc-blue w-full hover:bg-tc-blue"
		},
		imgLoadingType: {
			type: String,
			default: "lazy" //eager
		}
	},
	setup() {
		return {
		}
	}
}
</script>

<style>
.card {
    transition: transform 0.3s ease;
}

.card:hover {
    transform: scale(1.05);

}

.btn {
    border: none;
    transition: transform 0.7s ease;
}

.btn:hover {
    transform: scale(1.02);
}

.logo {
    width: auto !important;
    left: 50%;
    top: 35%;
    transform: translate(-50%, 0);
}
</style>
