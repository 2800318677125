// SeoManager.ts
export class SeoManager {
	setTitle(title: string) {
		document.title = title;
	}

	setMetaTag(attribute: 'name' | 'property', key: string, content: string) {
		let metaTag = document.querySelector(`meta[${attribute}="${key}"]`) as HTMLMetaElement;
		if (!metaTag) {
			metaTag = document.createElement('meta');
			metaTag.setAttribute(attribute, key);
			document.head.appendChild(metaTag);
		}
		metaTag.content = content;
	}

	setMetaDescription(description: string) {
		this.setMetaTag('name', 'description', description);
	}

	setMetaKeywords(keywords: string) {
		this.setMetaTag('name', 'keywords', keywords);
	}

	setOpenGraphTitle(title: string) {
		this.setMetaTag('property', 'og:title', title);
	}

	setOpenGraphDescription(description: string) {
		this.setMetaTag('property', 'og:description', description);
	}

	setOpenGraphImage(imageUrl: string) {
		this.setMetaTag('property', 'og:image', imageUrl);
	}

	updateSeo(title: string, description: string, keywords: string, imageUrl: string|undefined = undefined, updateSocialMediaSeo = true) {
		this.setTitle(title);
		this.setMetaDescription(description);
		this.setMetaKeywords(keywords);
		if (updateSocialMediaSeo) {
			//window.location.origin + '/assets/images/logo.png'
			this.updateOpenGraph(title, description, imageUrl);
		}
	}

	updateOpenGraph(title: string, description: string, imageUrl: string|undefined) {
		this.setOpenGraphTitle(title);
		this.setOpenGraphDescription(description);
		if (imageUrl) {
			this.setOpenGraphImage(imageUrl);
		}
	}
}
