
import { defineComponent, computed, PropType, ref, watch } from 'vue'
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/vue/24/outline'
import {RouteChecker} from "@/theconcept/core/route_checker";
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'Messenger',
  components: {
    ChatBubbleBottomCenterTextIcon,
  },
  props: {
    bounceCount: {
      type: Number as PropType<number>,
      default: 4.5,
    },
    showAlways: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const bounceStyle = computed(() => ({
      '--bounce-count': props.bounceCount,
    }))


    const activeRoutes = ['/'];
    const routeChecker = new RouteChecker(activeRoutes);
    const route = useRoute();
    const isActive = ref<boolean>(routeChecker.isRouteActive(route.path)) || props.showAlways;

    watch(
        () => route.path,
        (newPath) => {
          if (props.showAlways) {
            isActive.value = true;
            return;
          }
          isActive.value = routeChecker.isRouteActive(newPath);
        }
    );

    return {
      bounceStyle,
      isActive,
    }
  },
})
