import {computed, ComputedRef} from "vue";
import store from "@/store";
import router from '@/router';
import {SeoManager} from '@/theconcept/core/seo_manager';

export default function useSystem() {
	const internetConnection: ComputedRef<boolean> = computed(() => store.getters['system/getInternetConnection']);
	const appVersion: ComputedRef<string | null> = computed(() => store.getters['system/appVersion']);
	const appActiveState: ComputedRef<boolean> = computed(() => store.getters['system/appActiveState']);
	const backgroundTrackingActive: ComputedRef<boolean> = computed(() => store.getters['system/backgroundTrackingActive']);
	const hasDeveloperMode: ComputedRef<boolean> = computed(() => store.getters['system/hasDeveloperMode']);

	let seoManager: SeoManager;

	function hasInternetConnection(): boolean {
		return internetConnection.value;
	}

	function setInternetConnection(connectionState: boolean) {
		store.commit('system/setInternetConnection', connectionState)
	}

	function setAppVersion(appVersion: string) {
		store.commit('system/appVersion', appVersion)
	}

	function setAppActiveState(activeState: boolean) {
		store.commit('system/appActiveState', activeState)
	}

	function setBackgroundTrackingActive(activeState: boolean) {
		store.commit('system/backgroundTrackingActive', activeState)
	}

	function setDeveloperMode(value: boolean) {
		store.commit('system/setDeveloperMode', value)
	}

	function isDeveloper(): boolean {
		let result = false;
		try {
			if (hasDeveloperMode.value) {
				result = true;
			}
		} catch (error) {
			//
		}
		return result;
	}

	function isDevelopmentEnv(): boolean {
		return process.env.VUE_APP_ENV === 'development'
	}


	function isMobileDevice(): boolean
	{
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	}

	function initVersion() {
		if (process.env.VUE_APP_VERSION && (!appVersion.value || (appVersion.value !== process.env.VUE_APP_VERSION))) {
			setAppVersion(process.env.VUE_APP_VERSION)
		}
	}


	const scrollToElement = (elementId: string, extraOffset = 0, timeout = 0, block: ScrollLogicalPosition = 'center', inline: ScrollLogicalPosition = 'nearest', maxAttempts = 3, updateUrl = false) => {
		try {
			setTimeout(() => {
				try {
					const element = document.querySelector('#'+elementId);
					if (!element) {
						throw new Error('element ' + elementId + ' not found');
					}
					element.scrollIntoView({
						behavior: 'smooth',
						block: block,
						inline: inline
					});

					if (updateUrl) {
						const currentUrl = window.location.href.split('#')[0];
						history.pushState(null, '', `${currentUrl}#${elementId}`);
					}
				} catch (e) {
					if (maxAttempts > 0) {
						const waitTimeout = 1000 + (maxAttempts === 1 ? 1000 : 500);
						scrollToElement(elementId, extraOffset, waitTimeout, block, inline, maxAttempts - 1);
					} else {
						if (process.env.NODE_ENV === "development") {
							console.error('cannot scroll to element ' + elementId + ' not found!');
						}
					}
					return;
				}

			}, timeout);
		} catch (err) {console.log(err)}
	}

	async function copyContent(content: string) {
		try {
			content = decodeURI(content);
			await navigator.clipboard.writeText(content);
		} catch (err) {
			console.error(err);
		}
	}

	function getUrls(input: string): string[] {
		if (!input || !input.length) {
			return [];
		}
		const urlRegex = /(https?:\/\/[^\s]+)/g;
		const urls = input.match(urlRegex);
		return urls || [];
	}

	function getFirstUrl(input: string): string | null {
		const urls = getUrls(input);
		return urls.length > 0 ? urls[0] : null;
	}

	function extractImageUrl_by_HtmlImage(htmlString: string): string | null {
		const regex = /<img.*?src="(.*?)"/;
		const match = regex.exec(htmlString);
		if (match && match.length > 1) {
			return match[1];
		}
		return null;
	}

	async function downloadImage(url: string): Promise<void> {
		try {
			// Fetch the image data
			const response = await fetch(url);

			// Convert the response to blob
			const blob = await response.blob();

			// Create a temporary anchor element
			const anchor = document.createElement('a');

			// Create an object URL for the blob
			const objectUrl = URL.createObjectURL(blob);

			// Set the anchor's href to the object URL
			anchor.href = objectUrl;

			// Set the anchor's download attribute to force download
			anchor.download = 'image.jpg';

			// Programmatically click the anchor to trigger download
			anchor.click();

			// Clean up by revoking the object URL
			URL.revokeObjectURL(objectUrl);
		} catch (error) {
			console.error('Error downloading image:', error);
		}
	}

	async function copyImageToClipboard(imageUrl: string) {
		try {
			const image = getFirstUrl(imageUrl);
			if (!image) {
				throw new Error('Invalid Image Link, ' + imageUrl);
			}
			const response = await fetch(image);
			const blob = await response.blob();

			// Change the MIME type here to match the actual image type
			const mimeType = blob.type || 'image/png'; // If blob type is not available, default to 'image/png'
			const clipboardItemInput: any = {};
			clipboardItemInput[mimeType] = blob;

			const clipboardItem = new ClipboardItem(clipboardItemInput);
			await navigator.clipboard.write([clipboardItem]);
		} catch (err) {
			console.error(err);
			const image = getFirstUrl(imageUrl);
			if (!image) {
				throw new Error('Invalid Image Link, ' + imageUrl);
			}
			await navigator.clipboard.writeText(image);
		}
	}

	async function downloadUsingFetch(fileUrl: string, fileName = '') {
		fetch(fileUrl).then(res => res.blob()).then(file => {
			const tempUrl = URL.createObjectURL(file);
			const aTag = document.createElement("a");
			aTag.href = tempUrl;
			if (fileName.length === 0) {
				fileName = fileUrl.replace(/^.*[\\/]/, '');
			}
			aTag.download = fileName;
			document.body.appendChild(aTag);
			aTag.click();
			URL.revokeObjectURL(tempUrl);
			aTag.remove();
		}).catch(() => {
			//
		});
	}

	function deepCopy(source: any): any {
		return Object.create(source);
	}
	function prepareDownload(content: BlobPart, fileName: string, contentType: string) {
		const a = document.createElement("a");
		const file = new Blob([content], { type: contentType });
		a.href = URL.createObjectURL(file);
		a.download = fileName;
		a.click();
	}

	function downloadJsonFile(jsonData: any, fileName: string){
		prepareDownload(JSON.stringify(jsonData), fileName+".json", "text/plain");
	}

	function addLeadingSlash(str: string): string {
		if (!str.startsWith('/')) {
			return '/' + str;
		}
		return str;
	}

	/**
	 * Extracts the ID from an anchor string (e.g. "#test123" -> "test123").
	 * @param anchor - The anchor string to extract the ID from.
	 * @returns The extracted ID.
	 */
	function getIdFromAnchor(anchor: string): string {
		if (anchor.startsWith('#')) {
			return anchor.substring(1);
		}
		return anchor;
	}

	/**
	 * Retrieves the anchor from the current URL, if one exists.
	 * @returns The anchor (without the '#' symbol) or null if no anchor is present.
	 */
	function getAnchorFromUrl(): string | null {
		const hash = window.location.hash;
		if (hash) {
			return hash.substring(1); // Remove the '#' character
		}
		return null;
	}

	function getQueryByKey(key: string) {
		const queryParams = new URLSearchParams(window.location.search);
		return queryParams.get(key);
	}

	function redirectIfQueryParamExists(defaultTimeout = 200) {
		const redirect = getQueryByKey('redirect');
		if (redirect) {
			const redirectUrl = addLeadingSlash(String(redirect));
			const searchURL = new URL(window.location.href);
			searchURL.searchParams.delete('redirect');
			window.history.pushState({}, '', searchURL);

			setTimeout(() => {
				return router.push(redirectUrl);
			}, defaultTimeout)
		}
	}

	function isArrayOrSingleObject(value: any): 'array' | 'object' | 'neither' {
		if (Array.isArray(value)) {
			// Check if all elements in the array are objects
			if (value.every((element) => typeof element === 'object' && element !== null)) {
				return 'array';
			}
		} else if (typeof value === 'object' && value !== null) {
			return 'object';
		}
		return 'neither';
	}

	function areArraysDifferent<T>(arr1: T[], arr2: T[]): boolean {
		// If lengths are different, arrays are definitely different
		if (arr1.length !== arr2.length) {
			return true;
		}

		// Check each element in the arrays
		for (let i = 0; i < arr1.length; i++) {
			if (arr1[i] !== arr2[i]) {
				return true; // If any element differs, arrays are different
			}
		}

		// If all elements match, arrays are the same
		return false;
	}


	function getLabelClassByColor(color: string) {
		const ringColor = color === 'green' ? '20' : '10';
		return 'bg' + color + '-50 text-' + color + '-700 ring-' + color + '-600/' + ringColor;
	}

	function isFeatureFlagActive(flag: string): boolean {
		const queryFlag = getQueryByKey('flag')?.toString();
		return queryFlag === flag;
	}

	function isJson(str: string): boolean {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}

	function getRandomNumber(starts = 1, max = 9000000) {
		return Math.floor(Math.random() * max) + starts;
	}


	function fileStartsWithHttps(filePath: string, checkHttps = true): boolean {
		return !(checkHttps && !filePath.startsWith("https://"));
	}

	function buildRoute(path: string, params?: Record<string, string | number | boolean>): string {
		let baseUrl = path;
		if (params) {
			const queryParams = Object.entries(params)
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			.filter(([key, value]) => value !== '' && value !== null && value !== undefined)
			.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
			.join('&');
			if (queryParams) {
				baseUrl += `?${queryParams}`;
			}
		}
		return baseUrl;
	}

	function calculateTotalLocalStorageUsage(): string {
		let total = 0;
		for (const key in localStorage) {
			const value: any = localStorage.getItem(key);
			total += (new TextEncoder().encode(value)).length;
		}
		const inKB = (total / 1024);
		return inKB.toFixed(2);
	}

	function getSeoManager(): SeoManager
	{
		if (!seoManager) {
			seoManager = new SeoManager();
		}
		return seoManager;
	}

	const isValidEmail = (email: string): boolean => {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(email);
	};


	return {
		internetConnection,
		appVersion,
		hasInternetConnection,
		setInternetConnection,
		setAppVersion,
		initVersion,
		isDevelopmentEnv,
		isDeveloper,
		appActiveState,
		setAppActiveState,
		backgroundTrackingActive,
		setBackgroundTrackingActive,
		setDeveloperMode,
		hasDeveloperMode,
		scrollToElement,
		copyContent,
		downloadUsingFetch,
		deepCopy,
		downloadJsonFile,
		addLeadingSlash,
		redirectIfQueryParamExists,
		getQueryByKey,
		isArrayOrSingleObject,
		getLabelClassByColor,
		isJson,
		copyImageToClipboard,
		extractImageUrl_by_HtmlImage,
		downloadImage,
		isFeatureFlagActive,
		getRandomNumber,
		fileStartsWithHttps,
		areArraysDifferent,
		buildRoute,
		calculateTotalLocalStorageUsage,
		isMobileDevice,
		getSeoManager,
		getIdFromAnchor,
		getAnchorFromUrl,
		isValidEmail,
	}
}
