import {WizzardFactory} from '@/theconcept/wizzard/wizzard_factory';

export default function useWizzardFactory() {

	let wizzardFactory: WizzardFactory | null = null;
	const getWizzardFactory = (): WizzardFactory => {
		if (!wizzardFactory) {
			wizzardFactory = new WizzardFactory();
		}
		return wizzardFactory;
	}

	return {
		getWizzardFactory,
	}
}