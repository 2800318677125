import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7bff8251"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-12 float-right" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "flex h-full" }
const _hoisted_5 = { class: "hidden md:block" }
const _hoisted_6 = { class: "mx-4 flex justify-between h-full items-center" }
const _hoisted_7 = {
  id: "mobile-menu",
  class: "md:hidden"
}
const _hoisted_8 = { class: "mx-4 flex justify-between h-full items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChatBubbleBottomCenterTextIcon = _resolveComponent("ChatBubbleBottomCenterTextIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.isActive)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "mr-5",
        style: _normalizeStyle([_ctx.bounceStyle, {"position":"fixed","right":"0","bottom":"0","width":"1%","z-index":"9"}])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_router_link, {
                      alt: "Get in touch with us",
                      title: "Get in touch with us",
                      class: _normalizeClass(["transition text-black sticky content-center bg-tc-blue hover:bg-tc-blue btn btn-ghost btn-sm mx-4 p-6 rounded-btn", {'animate-bounce temporary-bounce': _ctx.bounceCount}]),
                      to: "/contact"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChatBubbleBottomCenterTextIcon, {
                          class: "h-6 w-6",
                          "aria-hidden": "true",
                          alt: "Get in touch with us",
                          title: "Get in touch with us"
                        })
                      ]),
                      _: 1
                    }, 8, ["class"])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_router_link, {
                class: _normalizeClass(["transition text-black sticky content-center bg-tc-blue hover:bg-tc-blue btn btn-ghost btn-sm mx-4 p-6 rounded-btn", {'animate-bounce temporary-bounce': _ctx.bounceCount}]),
                to: "/contact"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ChatBubbleBottomCenterTextIcon, {
                    class: "h-6 w-6",
                    "aria-hidden": "true"
                  })
                ]),
                _: 1
              }, 8, ["class"])
            ])
          ])
        ])
      ], 4))
    : _createCommentVNode("", true)
}