import {
	AbstractWizzard,
	WizzardStepStatus,
} from '@/theconcept/wizzard/base/abstract_wizzard';
import {
	StepSummaryDataInterface,
} from '@/theconcept/wizzard/base/wizzard_summary';
import {
	AiIntegrationServices,
	ApiServices,
	ArVrDevelopmentServices,
	BrandingServices,
	DesignServices,
	ProductDevelopmentServices,
	ProjectTypes,
	ServiceTypeArray,
	WebDevelopmentServices,
	WizzardAdditionalDataInterface,
	WizzardAiProjectTypes,
	WizzardApiProjectTypes,
	WizzardArVrDevelopmentProjectTypes,
	WizzardDesignProjectTypes,
	WizzardPriorityTypesInterface,
	WizzardPriorityTypesValues,
	WizzardProductDevelopmentProjectTypes,
	WizzardProjectTypesInterface,
	WizzardServicesInterface,
	WizzardType,
	WizzardWebDevelopmentProjectTypes,
} from '@/theconcept/wizzard/base/wizzard.interface';
import {RadioButtonItem} from '@/components/inputs/SingleRadioButton.vue';
import {CheckBoxItem} from "@/components/inputs/MultiCheckBox.vue";
import useCustomStore from "@/composable/custom/useCustomStore";

interface WizzardWebDevelopmentData {
	companyService: RadioButtonItem|undefined;
	companyBranch: RadioButtonItem|undefined;
	projectType: string;
	projectDescription: string;
	services: CheckBoxItem[];
	budget: string;
	priorityType: string,
	timeRange: string;
	message: string;
	contactInfo: any;
}

export abstract class AbstractWizzardService extends AbstractWizzard implements WizzardAdditionalDataInterface, WizzardProjectTypesInterface, WizzardServicesInterface, WizzardPriorityTypesInterface {
	protected data: WizzardWebDevelopmentData | undefined;

	private _additionalData: any;
	private _companyDetails: any;
	private _projectTypes: any;
	private _priorityTypes: any;
	private _services: any;


	constructor() {
		super();
		this.currentStep = 1;
		this.initData();
	}

	initData() {
		this.data = {
			companyService: undefined,
			companyBranch: undefined,
			projectType: '',
			projectDescription: '',
			services: [],
			budget: '',
			priorityType: '',
			timeRange: '',
			message: '',
			contactInfo: undefined,
		};
	}

	getData(): WizzardWebDevelopmentData | undefined {
		return this.data;
	}

	hasData(): boolean {
		return !!this.data;
	}

	get additionalData(): any {
		return this._additionalData;
	}

	set additionalData(value: any) {
		this._additionalData = value;
	}

	get companyDetails(): any {
		return this._companyDetails;
	}

	set companyDetails(value: any) {
		this._companyDetails = value;
	}

	get projectTypes(): Array<string> {
		if (this._projectTypes && this._projectTypes.length > 0) {
			return this._projectTypes;
		}
		const sources: Array<string> = [];

		this.getProjectTypes().forEach((projectType) => {
			const projectTypeTranslation = this.getTranslation(`wizzard.base.project_details.${this.getType()}.project_types.${projectType}`)
			if (projectTypeTranslation) {
				sources.push(projectTypeTranslation);
			}
		});

		this._projectTypes = sources;
		return this._projectTypes;
	}

	protected resetProjectTypes() {
		this._projectTypes = undefined;
	}

	get priorityTypes(): Array<string> {
		if (this._priorityTypes && this._priorityTypes.length > 0) {
			return this._priorityTypes;
		}
		const sources: Array<string> = [];

		WizzardPriorityTypesValues.forEach((priorityType) => {
			const priorityTypeTranslation = this.getTranslation(`wizzard.base.budget_timeline.fields.priority.${priorityType}`)
			if (priorityTypeTranslation) {
				sources.push(priorityTypeTranslation);
			}
		});

		this._priorityTypes = sources;
		return this._priorityTypes;
	}

	get services(): Array<CheckBoxItem> {
		if (this._services && this._services.length > 0) {
			return this._services;
		}
		const servicesArray: Array<CheckBoxItem> = [];

		this.getServiceTypes().forEach((serviceType) => {
			const serviceTypeTranslation = this.getTranslation(`wizzard.${this.getType()}.services.fields.${serviceType}`)
			if (serviceTypeTranslation) {
				servicesArray.push({
					reference: this.getReferenceWithoutSpecialChars(serviceTypeTranslation),
					title: serviceTypeTranslation,
					description: '',
					imageUrl: '',
					checked: false,
				});
			}
		});

		this._services = servicesArray;
		return this._services;
	}

	getStepTitle(step: number): string {
		switch (step) {
			case 1:
				return this.getTranslation('wizzard.base.company_details.progressbar');
			case 2:
				return this.getTranslation('wizzard.base.project_details.progressbar');
			case 3:
				return this.getTranslation(`wizzard.${this.getType()}.progressbar`);
			case 4:
				return this.getTranslation('wizzard.base.budget_timeline.progressbar');
			case 5:
				return this.getTranslation('wizzard.base.contact.progressbar');
			default:
				return '';
		}
	}

	setCompanyService(companyService: RadioButtonItem) {
		if (this.data) {
			this.data.companyService = companyService;
			return;
		}
	}

	setMessage(message: string) {
		if (this.data) {
			this.data.message = message;
			return;
		}
	}

	setCompanyBranch(companyBranch: RadioButtonItem) {
		if (this.data) {
			this.data.companyBranch = companyBranch;
			return;
		}
	}


	getProjectTypeDefaultValue() {
		if (this.data && this.data.projectType.length > 0 && this.projectTypes.length > 0) {
			return this.projectTypes.find((item) => item === this.data?.projectType);
		}

		return null;
	}

	setProjectType(projectType: string) {
		if (this.data) {
			this.data.projectType = projectType;
			return;
		}
	}

	setPriorityType(priorityType: string) {
		if (this.data) {
			this.data.priorityType = priorityType;
			return;
		}
	}

	getPriorityTypeDefaultValue() {
		if (this.data && this.data.priorityType.length > 0 && this.priorityTypes.length > 0) {
			return this.priorityTypes.find((item) => item === this.data?.priorityType);
		}

		return null;
	}

	setProjectDescription(projectDescription: string) {
		if (this.data) {
			this.data.projectDescription = projectDescription;
			return;
		}
	}

	setContactInfo(contactInfo: any) {
		if (this.data) {
			this.data.contactInfo = contactInfo;
			return;
		}
	}

	setService(service: CheckBoxItem[]) {
		if (this.data) {
			this.data.services = service;
			return;
		}
	}

	setBudget(budget: string) {
		if (this.data) {
			this.data.budget = budget;
			return;
		}
	}

	setTimeRange(timeRange: string) {
		if (this.data) {
			this.data.timeRange = timeRange;
			return;
		}
	}

	getStepsPath(): string {
		return 'template/WebDevelopment';
	}

	abstract getType(): WizzardType;

	getProjectTypes(): ProjectTypes | []
	{
		switch (this.getType()) {
			case WizzardType.WEB_DEVELOPMENT:
				return WizzardWebDevelopmentProjectTypes;
			case WizzardType.AI_INTEGRATION:
				return WizzardAiProjectTypes;
			case WizzardType.AR_VR_DEVELOPMENT:
				return WizzardArVrDevelopmentProjectTypes;
			case WizzardType.API:
				return WizzardApiProjectTypes;
			case WizzardType.PRODUCT_DEVELOPMENT:
				return WizzardProductDevelopmentProjectTypes;
			case WizzardType.BRANDING:
				return WizzardDesignProjectTypes;
			case WizzardType.DESIGN:
				return WizzardDesignProjectTypes;
			default:
				return [];
		}
	}

	getServiceTypes(): ServiceTypeArray | []
	{
		switch (this.getType()) {
			case WizzardType.WEB_DEVELOPMENT:
				return WebDevelopmentServices;
			case WizzardType.AI_INTEGRATION:
				return AiIntegrationServices;
			case WizzardType.AR_VR_DEVELOPMENT:
				return ArVrDevelopmentServices;
			case WizzardType.API:
				return ApiServices;
			case WizzardType.DESIGN:
				return DesignServices;
			case WizzardType.BRANDING:
				return BrandingServices;
			case WizzardType.PRODUCT_DEVELOPMENT:
				return ProductDevelopmentServices;
			default:
				return [];
		}
	}

	validateStep(step: number): boolean {
		switch (step) {
			case 1:
				return !!this.companyDetails;
			case 2:
				return !!this.data?.projectType;
			case 3:
				return !!(this.data?.services && this.data?.services.length > 0);
			case 4:
				return !!this.data?.priorityType;
			case 5:
				return !!this.data?.contactInfo;
			default:
				return super.validateStep(step);
		}
	}

	getStepComponentMap(): Record<number, string> {
		return {
			1: 'base/WizzardCompanyDetailsComponent',
			2: 'base/WizzardProjectServicesComponent',
			3: 'base/WizzardServiceSelectionComponent',
			4: 'base/WizzardBudgetPriorityComponent',
			5: 'base/WizzardContactInfoComponent',
		};
	}

	getStepsStatusInfo(): Record<number, WizzardStepStatus> {
		return {
			1: {
				step: 1,
				title: 'wizzard.base.company_details.progressbar',
				description: 'wizzard.base.company_details.description',
				current: this.currentStep === 1,
			},
			2: {
				step: 2,
				title: 'wizzard.base.project_details.progressbar',
				description: 'wizzard.base.project_details.description',
				current: this.currentStep === 2,
			},
			3: {
				step: 3,
				title: `wizzard.${this.getType()}.progressbar`,
				description: `wizzard.${this.getType()}.description`,
				current: this.currentStep === 3,
			},
			4: {
				step: 4,
				title: 'wizzard.base.budget_timeline.progressbar',
				description: 'wizzard.base.budget_timeline.description',
				current: this.currentStep === 4,
			},
			5: {
				step: 5,
				title: 'wizzard.base.contact.progressbar',
				description: 'wizzard.base.contact.description',
				current: this.currentStep === 5,
			},
		};
	}

	getWizzardSummaryDataByStep(step: number): StepSummaryDataInterface | undefined {
		switch (step) {
			case 1:
				return {
					step: 1,
					title: 'wizzard.base.company_details.progressbar',
					description: this.getCompanyDetails(),
				};
			case 2:
				return {
					step: 2,
					title: 'wizzard.base.project_details.progressbar',
					description: this.data?.projectDescription ?? '',
				};
			case 3:
				return {
					step: 3,
					title: `wizzard.${this.getType()}.progressbar`,
					description: this.getServicesSummaryDescription(),
				};
			case 4:
				return {
					step: 4,
					title: 'wizzard.base.budget_timeline.progressbar',
					description: this.getBudgetAndTimeSummaryDescription(),
				};
			case 5:
				return {
					step: 5,
					title: 'wizzard.base.contact.progressbar',
					description: this.getContactSummaryDescription(),
				};
			default:
				return undefined;
		}
	}

	protected getCompanyDetails(): string {
		if (!this.companyDetails) {
			return '';
		}
		return this.companyDetails.company_name ?? '' + ' - ' + this.companyDetails.industry ?? '' ;
	}

	protected getBudgetAndTimeSummaryDescription() {
		let description = '';
		if (this.data?.budget) {
			description += this.data?.budget + '€';
		}
		if (this.data?.priorityType && description.length > 0) {
			description += ', ' + this.data?.priorityType;
		}
		return description;
	}

	isSimulateAble(): boolean {
		return false;
	}

	protected getServicesSummaryDescription() {
		if (!this.data?.services) {
			return '';
		}
		const data = this.data?.services.map((service) => {
			return service.title;
		});
		return data.join(', ');
	}

	serializeForApiRequest(): string {
		const data = {
			type: this.getType(),
			data: {
				email: this.data?.contactInfo?.email,
				companyDetails: this.companyDetails,
				projectType: this.data?.projectType,
				projectDescription: this.data?.projectDescription,
				services: this.data?.services,
				budget: this.data?.budget,
				priorityType: this.data?.priorityType,
				timeRange: this.data?.timeRange,
				message: this.data?.message,
				contactInfo: this.data?.contactInfo,
			},
			language: useCustomStore().getLanguageLocale.value,
		}
		return JSON.stringify(data);
	}

	protected getContactSummaryDescription() {
		const contactData: Array<string> = [];
		if (this.data?.contactInfo) {
			if (this.data?.contactInfo.name) {
				contactData.push(this.data?.contactInfo.name);
			}
			if (this.data?.contactInfo.phone) {
				contactData.push(this.data?.contactInfo.phone);
			}
			if (this.data?.contactInfo.email) {
				contactData.push(this.data?.contactInfo.email);
			}
		}

		//make an is string check for the text
		const additionalData = this.additionalData?.text;
		if (additionalData && typeof additionalData === 'string') {
			contactData.push('Additional Info:' + additionalData);
		}
		return contactData.join(', ');
	}
}