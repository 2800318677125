import { Store, createLogger } from 'vuex';
import { createStore } from 'vuex-extensions';
import custom from "@/store/modules/custom";
import VuexPersistence, { PersistOptions } from 'vuex-persist';
// import lzstring from 'lz-string';

const debug = process.env.NODE_ENV !== 'production';

interface CustomPersistOptions<S> extends PersistOptions<S> {
  transformer?: {
    in: (state: S) => any;
    out: (state: any) => S;
  };
}

const vuexLocal = new VuexPersistence({
  // storage: window.sessionStorage,
  storage: window.localStorage,
  reducer: (state: any) => {
    return {
      custom: state.custom,
    }
  },
} as CustomPersistOptions<any>); // Cast to CustomPersistOptions

export default createStore(Store, {
  state: {},
  mutations: {},
  actions: {},
  modules: {
    custom,
  },
  strict: debug,
  plugins: debug ? [createLogger(), vuexLocal.plugin] : [vuexLocal.plugin],
});
