import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "bp-font rounded-3xl" }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.locale) = $event)),
      class: _normalizeClass(_ctx.cssClass),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeLanguage && _ctx.changeLanguage(...args))),
      "aria-label": "Choose your language"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.arrLanguageMap, (languageName, languageCode) => {
        return (_openBlock(), _createElementBlock("option", {
          key: languageCode,
          value: languageCode
        }, _toDisplayString(languageName), 9, _hoisted_2))
      }), 128))
    ], 34), [
      [_vModelSelect, _ctx.locale]
    ])
  ]))
}