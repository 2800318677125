import ApiService from "../base/api.service";

const resource = '/offer';

class ResponseError extends Error {
	errorCode: any;
	errorMessage: any;
	constructor(errorCode: any, message: string | undefined) {
		super(message);
		this.name = this.constructor.name;
		if (message != null) {
			this.message = message;
		}
		this.errorCode = errorCode;
	}
}

const OfferService = {
	create(payload: any) {
		return ApiService.put(`${resource}`, payload);
	},
}

export { OfferService, ResponseError };