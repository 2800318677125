import {AbstractWizzardService} from "@/theconcept/wizzard/base/abstract_wizzard_service";
import {WizzardType} from "@/theconcept/wizzard/base/wizzard.interface";

export class WizzardBranding extends AbstractWizzardService {
	getType(): WizzardType {
		return WizzardType.BRANDING;
	}

	getWizzardColor(): string {
		return 'bg-tc-brandingBtn';
	}

	getWizzardPrimaryTextColor(): string {
		return 'text-tc-brandingBtn';
	}
}