import {AbstractWizzardService} from "@/theconcept/wizzard/base/abstract_wizzard_service";
import {WizzardType} from "@/theconcept/wizzard/base/wizzard.interface";

export class WizzardAiIntegration extends AbstractWizzardService {
	getType(): WizzardType {
		return WizzardType.AI_INTEGRATION;
	}

	getWizzardColor(): string {
		return 'bg-tc-aiBtn';
	}

	getWizzardPrimaryTextColor(): string {
		return 'text-tc-aiBtn';
	}
}