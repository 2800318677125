
import {defineComponent, ref, toRef, onMounted, watch} from 'vue';
import useCustomStore, {ScriptInterface} from "@/composable/custom/useCustomStore";
import useTranslation from "@/composable/translation/useTranslation";
import {XMarkIcon} from '@heroicons/vue/24/outline';

export default defineComponent({
	name: 'CookiesDialog',
	components: {
    XMarkIcon,
	},
	props: {
		title: {type: String},
		description: {type: String},
		visible: {type: Boolean, default: true},
		showButton: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['confirm', 'cancelConfirm'],
	setup(props, {emit}) {
		const dialogTitle = toRef(props, 'title');
		const dialogDescription = toRef(props, 'description');
		const visible = toRef(props, 'visible');
		const dialogVisible = ref(visible.value);
		const informationVisible = ref(visible.value);
		const {
      getCookies,
      setCookies,
      setCookieDetails,
      createScriptElement,
      appendScriptElement,
      isFullCookieConsentAllowed,
      removeScripts,
      setIsCookieScriptsDeleted,
      isCookieScriptsDeleted,
		} = useCustomStore();
		const cookies = ref(getCookies);
		const {t,} = useTranslation();
    const hasCookiesAccepted = ref(true);
    const cookieScriptList = ref<Array<ScriptInterface>>();

    function initCookieScriptList(force = false) {
      if (!cookieScriptList.value || force) {
        if (process.env.NODE_ENV === 'production') {
          cookieScriptList.value = [
            {
              'src': 'https://www.googletagmanager.com/gtag/js?id=' + process.env.VUE_APP_GOOGLE_ANALYTICS_PROPERTY_ID,
              'arguments': {'async': true},
            },
            {
              'src': '',
              'arguments': {},
              'content': '\t\twindow.dataLayer = window.dataLayer || [];\n' +
                  '\t\tfunction gtag(){dataLayer.push(arguments);}\n' +
                  '\t\tgtag(\'js\', new Date());\n' +
                  '\t\tgtag(\'config\', "' + process.env.VUE_APP_GOOGLE_ANALYTICS_PROPERTY_ID + '");\n' +
                  '\t\tgtag(\'consent\', "default", {\n' +
                  '\'ad_user_data\': \'denied\',' +
                  '\'ad_personalization\': \'denied\',' +
                  '\'ad_storage\': \'denied\',' +
                  '\'analytics_storage\': \'denied\',' +
                  '});\n' +
                  '\t\tgtag(\'consent\', "update", {\n' +
                  '\'ad_user_data\': \'granted\',' +
                  '\'ad_personalization\': \'granted\',' +
                  '\'ad_storage\': \'granted\',' +
                  '\'analytics_storage\': \'granted\',' +
                  '});',
            },
            {
              'src': '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
              'arguments': {'async': true},
            },
          ];
        } else {
          cookieScriptList.value = [
            // {
            //   'src': 'https://www.googletagmanager.com/gtag/js?id=' + process.env.VUE_APP_GOOGLE_ANALYTICS_PROPERTY_ID,
            //   'arguments': {'async': true},
            // },
            {
              'src': '',
              'arguments': {},
              'content': '\t\twindow.dataLayer = window.dataLayer || [];\n' +
                  '\t\tfunction gtag(){dataLayer.push(arguments);}\n' +
                  '\t\tgtag(\'js\', new Date());\n' +
                  '\t\tgtag(\'config\', "' + process.env.VUE_APP_GOOGLE_ANALYTICS_PROPERTY_ID + '");\n' +
                  '\t\tgtag(\'consent\', "default", {\n' +
                  '\'ad_user_data\': \'denied\',' +
                  '\'ad_personalization\': \'denied\',' +
                  '\'ad_storage\': \'denied\',' +
                  '\'analytics_storage\': \'denied\',' +
                  '});\n' +
                  '\t\tgtag(\'consent\', "update", {\n' +
                  '\'ad_user_data\': \'granted\',' +
                  '\'ad_personalization\': \'granted\',' +
                  '\'ad_storage\': \'granted\',' +
                  '\'analytics_storage\': \'granted\',' +
                  '});',
            },
            {
              'src': '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
              'arguments': {'async': true},
            },
          ];
        }
      }
    }

		function checkAdditionalScripts() {
			if (isFullCookieConsentAllowed()) {
        initCookieScriptList();
        if (!cookieScriptList.value) {
          return;
        }
				cookieScriptList.value.forEach((value) => {
					let scriptSrc = '';
					if (value.src) {
						scriptSrc = value.src;
					}
					let scriptArguments = {};
					if (value.arguments) {
						scriptArguments = value.arguments;
					}
					let scriptContent = '';
					if (value.content) {
						scriptContent = value.content;
					}
					let scriptType = '';
					if (value.type) {
						scriptType = value.type;
					}
					const scriptElement = createScriptElement(scriptSrc, scriptArguments, scriptContent, scriptType);
					appendScriptElement(scriptElement);
        });
        setIsCookieScriptsDeleted(false);
      } else {
        removeAdditionalScripts();
      }
		}

		function checkCookiesAccepted() {
			const result = cookies.value === true;
			if (result) {
				checkAdditionalScripts();
			}
      hasCookiesAccepted.value = result;
      dialogVisible.value = !result;
		}

    function removeAdditionalScripts() {
      if (isCookieScriptsDeleted.value) {
        return;
      }
      initCookieScriptList();
      if (!cookieScriptList.value) {
        return;
      }
      removeScripts(cookieScriptList.value);
      cookieScriptList.value = undefined;
      setIsCookieScriptsDeleted(true);
    }

		function toggleDialog() {
			dialogVisible.value = !dialogVisible.value;
		}

		function toggleInformation() {
			informationVisible.value = !informationVisible.value;
		}

		function hideDialog() {
			dialogVisible.value = false;
		}

		const cancelDialog = () => {
      hideDialog();
			emit('cancelConfirm');
		};

		function confirm(essential = false) {
      hideDialog();
			setCookies(true);
			if (essential) {
				setCookieDetails({all: true, minimal: false});
			} else {
				setCookieDetails({all: false, minimal: true});
			}
			checkAdditionalScripts();
			emit('confirm');
		}

    watch(() => getCookies.value, (newValue) => {
      cookies.value = newValue;
      checkCookiesAccepted();
    });

		onMounted(() => {
			checkCookiesAccepted();
		});

		return {
			dialogTitle,
			dialogDescription,
			dialogVisible,
			informationVisible,
			toggleDialog,
			toggleInformation,
			confirm,
			cancelDialog,
			hasCookiesAccepted,
			t,
			cookies
		}
	},
})
