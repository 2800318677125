<template>
	<div :class="['relative', mainCss]">
		<div class="overflow-hidden" :style="{ height: carouselHeight }">
			<div class="transform-gpu" :style="scrollStyle">
				<Carousel
						v-if="getSlides"
						:headerText="headerText"
						:header-text-active="headerTextActive"
						:change-direction="changeDirection"
						:loop="loop"
						:slides="getSlides"
						:templateCss="templateCss"
						:swiper-slide-css="swiperSlideCss"
						:direction="direction"
						:padding="padding"
						:margin="margin"
						:speed="speed"
            :free-mode="freeMode"
            :allow-touch-move="allowTouchMove"
            :imgLoadingType="imgLoadingType"
				/>
			</div>
		</div>
	</div>
</template>


<script>
import { ref, onMounted, computed } from 'vue';
import {useI18n} from 'vue-i18n';
import Carousel from "@/components/carousel/Carousel.vue";

export default {
  name: "SolutionsCarousel",
  components: {
    Carousel,
  },
  props: {
    headerText: {
      type: String,
      default: "Our Solutions",
    },
    headerTextActive: {
      type: Boolean,
      default: false,
    },
    changeDirection: {
      type: Boolean,
      default: false,
    },
    templateCss: {
      type: String,
      default: 'mb-56',
    },
    padding: {
      type: String,
      default: 'pt-10 px-4 pb-4 text-5xl',
    },
    mainCss: {
      type: String,
      default: '', //Horizontal / Vertial ;)  - carousel-mask
    },
    swiperSlideCss: {
      type: String,
      default: 'space-x-4',
    },
    direction: {
      type: String,
      default: 'horizontal', //vertical
    },
    margin: {
      type: String,
      default: 'mb-4',
    },
    speed: {
      type: Number,
      default: 25000,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    useDefaultButtonColors: {
      type: Boolean,
      default: false,
    },
    useCombinedSlides: {
      type: Boolean,
      default: false,
    },
    freeMode: {
      type: Boolean,
      default: true,
    },
    allowTouchMove: {
      type: Boolean,
      default: true,
    },
    smallImages: {
      type: Boolean,
      default: false,
    },
    imgLoadingType: {
      type: String,
      default: "lazy" //eager
    }
  },
  setup(props) {
    const {t} = useI18n();

    const slides = ref([
      {
        id: "9",
        colorBtnService: "bg-tc-aiBtn hover:bg-tc-aiBtn",
        colorBtnApp: "bg-tc-aiBtn hover:bg-tc-aiBtn",
        pictureSrc2Active: false,
        cardButtonLinkActive: true,
        cardButton: 'solutions.SolutionsBtn',
        cardButtonLink: "/ai-integration",
        cardDescription: 'solutions.Solution7Info',
        cardNewInfo: "",
        cardInfo: "",
        cardLaterInfo: "",
        cardVersionInfo: "",
        cardTitle: 'solutions.Solution7Title',
        pictureSrc: `https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/services/webp/${props.smallImages ? 'sm/' : ''}ai-integration-banner.webp`,
        pictureSrc2: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/services/webp/tc_logo_s.webp",
        imgLoadingType: "eager",
      },
      {
        id: "4",
        colorBtnService: "bg-tc-webBtn hover:bg-tc-webBtn",
        colorBtnApp: "bg-tc-webBtn hover:bg-tc-webBtn",
        pictureSrc2Active: false,
        cardButtonLinkActive: true,
        cardButton: 'solutions.SolutionsBtn',
        cardButtonLink: "/web-development",
        cardDescription: 'solutions.Solution2Info',
        cardNewInfo: "",
        cardInfo: "",
        cardLaterInfo: "",
        cardVersionInfo: "",
        cardTitle: 'solutions.Solution2Title',
        pictureSrc: `https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/services/webp/${props.smallImages ? 'sm/' : ''}web-development_banner.webp`,
        pictureSrc2: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/services/webp/tc_logo_s.webp"
      },
      {
        id: "11",
        colorBtnService: "bg-tc-vrBtn hover:bg-tc-vrBtn",
        colorBtnApp: "bg-tc-vrBtn hover:bg-tc-vrBtn",
        pictureSrc2Active: false,
        cardButtonLinkActive: true,
        cardButton: 'solutions.SolutionsBtn',
        cardButtonLink: "/ar-vr-development",
        cardDescription: 'solutions.Solution8Info',
        cardNewInfo: "",
        cardInfo: "",
        cardLaterInfo: "",
        cardVersionInfo: "",
        cardTitle: 'solutions.Solution8Title',
        pictureSrc: `https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/services/webp/${props.smallImages ? 'sm/' : ''}vr-ar-development-banner.webp`,
        pictureSrc2: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/services/webp/tc_logo_s.webp"
      },
      {
        id: "5",
        colorBtnService: "bg-tc-productBtn hover:bg-tc-productBtn",
        colorBtnApp: "bg-tc-productBtn hover:bg-tc-productBtn",
        pictureSrc2Active: false,
        cardButtonLinkActive: true,
        cardButton: 'solutions.SolutionsBtn',
        cardButtonLink: "/product-development",
        cardDescription: 'solutions.Solution3Info',
        cardNewInfo: "",
        cardInfo: "",
        cardLaterInfo: "",
        cardVersionInfo: "",
        cardTitle: 'solutions.Solution3Title',
        pictureSrc: `https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/services/webp/${props.smallImages ? 'sm/' : ''}product-development_banner.webp`,
        pictureSrc2: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/services/webp/tc_logo_s.webp"
      },
      {
        id: "7",
        colorBtnService: "bg-tc-brandingBtn hover:bg-tc-brandingBtn",
        colorBtnApp: "bg-tc-brandingBtn hover:bg-tc-brandingBtn",
        pictureSrc2Active: false,
        cardButtonLinkActive: true,
        cardButton: 'solutions.SolutionsBtn',
        cardButtonLink: "/branding",
        cardDescription: 'solutions.Solution5Info',
        cardNewInfo: "",
        cardInfo: "",
        cardLaterInfo: "",
        cardVersionInfo: "",
        cardTitle: 'solutions.Solution5Title',
        pictureSrc: `https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/services/webp/${props.smallImages ? 'sm/' : ''}branding_banner.webp`,
        pictureSrc2: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/services/webp/tc_logo_s.webp",
        imgLoadingType: "eager",
      },
      {
        id: "8",
        colorBtnService: "bg-tc-apiBtn hover:bg-tc-apiBtn",
        colorBtnApp: "bg-tc-apiBtn hover:bg-tc-apiBtn",
        pictureSrc2Active: false,
        cardButtonLinkActive: true,
        cardButton: 'solutions.SolutionsBtn',
        cardButtonLink: "/api",
        cardDescription: 'solutions.Solution6Info',
        cardNewInfo: "",
        cardInfo: "",
        cardLaterInfo: "",
        cardVersionInfo: "",
        cardTitle: 'solutions.Solution6Title',
        pictureSrc: `https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/services/webp/${props.smallImages ? 'sm/' : ''}api_banner.webp`,
        pictureSrc2: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/services/webp/tc_logo_s.webp"
      },
      {
        id: "1",
        colorBtnService: "bg-tc-grBtn hover:bg-tc-grBtn",
        colorBtnApp: "bg-tc-grBtn hover:bg-tc-grBtn",
        pictureSrc2Active: true,
        cardButtonLinkActive: false,
        cardButton: 'solutions.AppLaunch',
        cardButtonLink: "https://www.greeve.ai",
        cardDescription: 'solutions.App4Info',
        cardNewInfo: "new",
        cardInfo: "",
        cardLaterInfo: "",
        cardVersionInfo: "beta",
        cardTitle: 'solutions.App4Title',
        pictureSrc: `https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/services/webp/${props.smallImages ? 'sm/' : ''}gr_banner.webp`,
        pictureSrc2: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/services/webp/greeve_logo.webp",
        imgLoadingType: "eager",
      },
      {
        id: "2",
        colorBtnService: "bg-tc-tcBtn hover:bg-tc-tcBtn",
        colorBtnApp: "bg-tc-tcBtn hover:bg-tc-tcBtn",
        pictureSrc2Active: true,
        cardButtonLinkActive: false,
        cardButton: 'solutions.AppLaunch',
        cardButtonLink: "https://www.theconcept.app",
        cardDescription: 'solutions.App2Info',
        cardNewInfo: "",
        cardInfo: "",
        cardLaterInfo: "mid 2025",
        cardVersionInfo: "",
        cardTitle: 'solutions.App2Title',
        pictureSrc: `https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/services/webp/${props.smallImages ? 'sm/' : ''}tc_banner_mode_everyone.webp`,
        pictureSrc2: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/services/webp/tc_logo_s.webp"
      },
      {
        id: "3",
        colorBtnService: "bg-tc-bpBtn hover:bg-tc-bpBtn",
        colorBtnApp: "bg-tc-bpBtn hover:bg-tc-bpBtn",
        pictureSrc2Active: true,
        cardButtonLinkActive: false,
        cardButton: 'solutions.AppLaunch',
        cardButtonLink: "https://www.boostpack.app",
        cardDescription: 'solutions.App1Info',
        cardNewInfo: "",
        cardInfo: "",
        cardLaterInfo: "",
        cardVersionInfo: "V.1.0.9",
        cardTitle: 'solutions.App1Title',
        pictureSrc: `https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/services/webp/${props.smallImages ? 'sm/' : ''}bp_banner.webp`,
        pictureSrc2: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/services/webp/boostpack_logo.webp"
      },
      {
        id: "1",
        colorBtnService: "bg-tc-contactBtn hover:bg-tc-contactBtn",
        colorBtnApp: "bg-tc-contactBtn hover:bg-tc-contactBtn",
        pictureSrc2Active: false,
        cardButtonLinkActive: true,
        cardButton: 'solutions.SolutionsBtn',
        cardButtonLink: "/contact",
        cardDescription: 'contact.HeaderInfo',
        cardNewInfo: "",
        cardInfo: "",
        cardLaterInfo: "",
        cardVersionInfo: "",
        cardTitle: 'contact.HeaderTitle',
        pictureSrc: `https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/services/webp/${props.smallImages ? 'sm/' : ''}contact-banner.webp`,
        pictureSrc2: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/services/webp/tc_logo_s.webp"
      }
    ]);


    const getSlides = computed(() => {
      if (!props.useCombinedSlides) {
        return slides.value;
      }

      const middleIndex = Math.ceil(slides.value.length / 2);
      const left = slides.value.slice(0, middleIndex);
      const right = slides.value.slice(middleIndex);
      return props.changeDirection
          ? [...left.reverse(), ...right]
          : [...left, ...right.reverse()];
    });


    onMounted(() => {
      if (props.useDefaultButtonColors) {
        slides.value.forEach(slide => {
          slide.colorBtnService = 'bg-tc-green w-full hover:bg-tc-green';
          slide.colorBtnApp = 'bg-tc-blue w-full hover:bg-tc-blue';
        });
      }
    });

    return {
      t,
      getSlides,
    };
  }
};
</script>

<style scoped>
</style>
