import {createRouter, createWebHistory} from 'vue-router'
import Landing from '../components/Landing.vue'
import i18n from '../i18n';


const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing,
    meta: {
      title: i18n.global.t("meta.title.default"),
      metaTags: [
        {
          name: i18n.global.t("meta.title.default"),
          content: i18n.global.t("meta.landing.content")
        },
        {
          property: 'og:description',
          content: i18n.global.t("meta.landing.content")
        },
        {
          property: 'description',
          content: i18n.global.t("meta.landing.description")
        },
        {
          name: 'keywords',
          content: i18n.global.t("meta.landing.keywords")
        }
      ],
    }
    },
  {
    path: '/solutions',
    name: 'Solutions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Solutions.vue'),
    meta: {
      title: i18n.global.t("meta.solutions.title"),
      metaTags: [
        {
          name: i18n.global.t("meta.solutions.title"),
          content: i18n.global.t("meta.solutions.content")
        },
        {
          property: 'og:description',
          content: i18n.global.t("meta.solutions.content")
        },
        {
          property: 'description',
          content: i18n.global.t("meta.solutions.content")
        },
        {
          name: 'keywords',
          content: i18n.global.t("meta.solutions.keywords")
        }
      ],
    }
  },
  // {
  //   path: '/development',
  //   name: 'Development',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../components/Development.vue'),
  //   meta: {
  //     title: i18n.global.t("meta.title.default"),
  //     metaTags: [
  //       {
  //         name: i18n.global.t("meta.title.default"),
  //         content: i18n.global.t("meta.development.content")
  //       },
  //       {
  //         property: 'og:description',
  //         content: i18n.global.t("meta.development.content")
  //       },
  //       {
  //         property: 'description',
  //         content: i18n.global.t("meta.development.content")
  //       },
  //       {
  //         name: 'keywords',
  //         content: i18n.global.t("meta.development.keywords")
  //       }
  //     ],
  //   }
  // },
  {
    path: '/vision',
    name: 'Vision',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Vision.vue'),
    meta: {
      title: i18n.global.t("meta.vision.title"),
      metaTags: [
        {
          name: i18n.global.t("meta.vision.title"),
          content: i18n.global.t("meta.vision.content")
        },
        {
          property: 'og:description',
          content: i18n.global.t("meta.vision.content")
        },
        {
          property: 'description',
          content: i18n.global.t("meta.vision.content")
        },
        {
          name: 'keywords',
          content: i18n.global.t("meta.vision.keywords")
        }
      ],
    }
  },
  {
    path: '/company',
    name: 'Company',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Company.vue'),
    meta: {
      title: i18n.global.t("meta.company.title"),
      metaTags: [
        {
          name: i18n.global.t("meta.company.title"),
          content: i18n.global.t("meta.company.content")
        },
        {
          property: 'og:description',
          content: i18n.global.t("meta.company.content")
        },
        {
          property: 'description',
          content: i18n.global.t("meta.company.content")
        },
        {
          name: 'keywords',
          content: i18n.global.t("meta.company.keywords")
        }
      ],
    }  },
  {
    path: '/reference',
    name: 'Reference',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Reference.vue'),
    meta: {
      title: i18n.global.t("meta.reference.title"),
      metaTags: [
        {
          name: i18n.global.t("meta.reference.title"),
          content: i18n.global.t("meta.reference.content")
        },
        {
          property: 'og:description',
          content: i18n.global.t("meta.reference.content")
        },
        {
          property: 'description',
          content: i18n.global.t("meta.reference.content")
        },
        {
          name: 'keywords',
          content: i18n.global.t("meta.reference.keywords")
        }
      ],
    }
  },
  {
    path: '/certificates',
    name: 'Certificates',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Certification.vue'),
    meta: {
      title: i18n.global.t("meta.certificate.title"),
      metaTags: [
        {
          name: i18n.global.t("meta.certificate.content"),
          content: i18n.global.t("meta.certificate.content")
        },
        {
          property: 'og:description',
          content: i18n.global.t("meta.certificate.content")
        },
        {
          property: 'description',
          content: i18n.global.t("meta.certificate.content")
        },
        {
          name: 'keywords',
          content: i18n.global.t("meta.certificate.keywords")
        }
      ],
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Contact.vue'),
    meta: {
      title: i18n.global.t("meta.contact.title"),
      metaTags: [
        {
          name: i18n.global.t("meta.contact.title"),
          content: i18n.global.t("meta.contact.content")
        },
        {
          property: 'og:description',
          content: i18n.global.t("meta.contact.content")
        },
        {
          property: 'description',
          content: i18n.global.t("meta.contact.content")
        },
        {
          name: 'keywords',
          content: i18n.global.t("meta.contact.keywords")
        }
      ],
    }
  },
  {
    path: '/jobs',
    name: 'Jobs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/company/Jobs.vue'),
    meta: {
      title: i18n.global.t("meta.jobs.title"),
      metaTags: [
        {
          name: i18n.global.t("meta.jobs.title"),
          content: i18n.global.t("meta.jobs.content")
        },
        {
          property: 'og:description',
          content: i18n.global.t("meta.jobs.content")
        },
        {
          property: 'description',
          content: i18n.global.t("meta.jobs.content")
        },
        {
          name: 'keywords',
          content: i18n.global.t("meta.jobs.keywords")
        }
      ],
    }
  },
  {
    path: '/press-kit',
    name: 'PressKit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/company/PressKit.vue'),
    meta: {
      title: i18n.global.t("meta.pressKit.title"),
      metaTags: [
        {
          name: i18n.global.t("meta.pressKit.title"),
          content: i18n.global.t("meta.pressKit.content")
        },
        {
          property: 'og:description',
          content: i18n.global.t("meta.pressKit.content")
        },
        {
          property: 'description',
          content: i18n.global.t("meta.pressKit.content")
        },
        {
          name: 'keywords',
          content: i18n.global.t("meta.pressKit.keywords")
        }
      ],
    }
  },
  {
    path: '/api',
    name: 'API',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/services/Api.vue'),
    meta: {
      title: i18n.global.t("meta.api.title"),
      metaTags: [
        {
          name: i18n.global.t("meta.api.title"),
          content: i18n.global.t("meta.api.content")
        },
        {
          property: 'og:description',
          content: i18n.global.t("meta.api.content")
        },
        {
          property: 'description',
          content: i18n.global.t("meta.api.content")
        },
        {
          name: 'keywords',
          content: i18n.global.t("meta.api.keywords")
        }
      ],
    }
  },
  {
    path: '/web-development',
    name: 'Web Development',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/services/WebDevelopment.vue'),
    meta: {
      title: i18n.global.t("meta.webDevelopment.title"),
      metaTags: [
        {
          name: i18n.global.t("meta.webDevelopment.title"),
          content: i18n.global.t("meta.webDevelopment.content")
        },
        {
          property: 'og:description',
          content: i18n.global.t("meta.webDevelopment.content")
        },
        {
          property: 'description',
          content: i18n.global.t("meta.webDevelopment.content")
        },
        {
          name: 'keywords',
          content: i18n.global.t("meta.webDevelopment.keywords")
        }
      ],
    }
  },

  {
    path: '/ar-vr-development',
    name: 'AR/VR Development',
  // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/services/ArVrDevelopment.vue'),
    meta: {
      title: i18n.global.t("meta.webDevelopment.title"),
      metaTags: [
        {
          name: i18n.global.t("meta.webDevelopment.title"),
          content: i18n.global.t("meta.webDevelopment.content")
        },
        {
          property: 'og:description',
          content: i18n.global.t("meta.webDevelopment.content")
        },
        {
          property: 'description',
          content: i18n.global.t("meta.webDevelopment.content")
        },
        {
          name: 'keywords',
          content: i18n.global.t("meta.webDevelopment.keywords")
        }
      ],
    }
  },
  // {
  //   path: '/3d-printing',
  //   name: '3D Printing',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../components/services/3dPrinting.vue'),
  //   meta: {
  //     title: i18n.global.t("meta.title.default"),
  //     metaTags: [
  //       {
  //         name: i18n.global.t("meta.title.default"),
  //         content: i18n.global.t("meta.3dPrinting.content")
  //       },
  //       {
  //         property: 'og:description',
  //         content: i18n.global.t("meta.3dPrinting.content")
  //       },
  //       {
  //         property: 'description',
  //         content: i18n.global.t("meta.3dPrinting.content")
  //       },
  //       {
  //         name: 'keywords',
  //         content: i18n.global.t("meta.3dPrinting.keywords")
  //       }
  //     ],
  //   }
  // },
  {
    path: '/product-development',
    name: 'Product Development',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/services/ProductDevelopment.vue'),
    meta: {
      title: i18n.global.t("meta.productDevelopment.title"),
      metaTags: [
        {
          name: i18n.global.t("meta.productDevelopment.title"),
          content: i18n.global.t("meta.productDevelopment.content")
        },
        {
          property: 'og:description',
          content: i18n.global.t("meta.productDevelopment.content")
        },
        {
          property: 'description',
          content: i18n.global.t("meta.productDevelopment.content")
        },
        {
          name: 'keywords',
          content: i18n.global.t("meta.productDevelopment.keywords")
        }
      ],
    }
  },
  {
    path: '/ai-integration',
    name: 'AI Integration',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/services/AiIntegration.vue'),
    meta: {
      title: i18n.global.t("meta.aiIntegration.title"),
      metaTags: [
        {
          name: i18n.global.t("meta.aiIntegration.title"),
          content: i18n.global.t("meta.aiIntegration.content")
        },
        {
          property: 'og:description',
          content: i18n.global.t("meta.aiIntegration.content")
        },
        {
          property: 'description',
          content: i18n.global.t("meta.aiIntegration.content")
        },
        {
          name: 'keywords',
          content: i18n.global.t("meta.productDevelopment.keywords")
        }
      ],
    }
  },
  {
    path: '/branding',
    name: 'Branding',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/services/Branding.vue'),
    meta: {
      title: i18n.global.t("meta.branding.title"),
      metaTags: [
        {
          name: i18n.global.t("meta.branding.title"),
          content: i18n.global.t("meta.branding.content")
        },
        {
          property: 'og:description',
          content: i18n.global.t("meta.branding.content")
        },
        {
          property: 'description',
          content: i18n.global.t("meta.branding.content")
        },
        {
          name: 'keywords',
          content: i18n.global.t("meta.branding.keywords")
        }
      ],
    }
  },
  {
    path: '/funding',
    name: 'Funding',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Funding.vue'),
    meta: {
      title: i18n.global.t("meta.funding.title"),
      metaTags: [
        {
          name: i18n.global.t("meta.funding.title"),
          content: i18n.global.t("meta.funding.content")
        },
        {
          property: 'og:description',
          content: i18n.global.t("meta.funding.metaDescription")
        },
        {
          property: 'description',
          content: i18n.global.t("meta.funding.metaDescription")
        },
        {
          name: 'keywords',
          content: i18n.global.t("meta.funding.keywords")
        }
      ],
    }
  },
  {
    path: '/cookie-policy',
    name: 'CookiePolicy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/legal/CookiePolicy.vue'),
    meta: {
      title: i18n.global.t("meta.cookiePolicy.title"),
      metaTags: [
        {
          name: i18n.global.t("meta.cookiePolicy.title"),
          content: i18n.global.t("meta.cookiePolicy.content")
        },
        {
          property: 'og:description',
          content: i18n.global.t("meta.cookiePolicy.content")
        },
        {
          property: 'description',
          content: i18n.global.t("meta.cookiePolicy.content")
        },
        {
          name: 'keywords',
          content: i18n.global.t("meta.cookiePolicy.keywords")
        }
      ],
    }
  },
  {
    path: '/imprint',
    name: 'Imprint',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/legal/Imprint.vue'),
    meta: {
      title: i18n.global.t("meta.imprint.title"),
      metaTags: [
        {
          name: i18n.global.t("meta.imprint.title"),
          content: i18n.global.t("meta.imprint.content")
        },
        {
          property: 'og:description',
          content: i18n.global.t("meta.imprint.content")
        },
        {
          property: 'description',
          content: i18n.global.t("meta.imprint.content")
        },
        {
          name: 'keywords',
          content: i18n.global.t("meta.imprint.keywords")
        }
      ],
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/legal/PrivacyPolicy.vue'),
    meta: {
      title: i18n.global.t("meta.privacyPolicy.title"),
      metaTags: [
        {
          name: i18n.global.t("meta.privacyPolicy.title"),
          content: i18n.global.t("meta.privacyPolicy.content")
        },
        {
          property: 'og:description',
          content: i18n.global.t("meta.privacyPolicy.content")
        },
        {
          property: 'description',
          content: i18n.global.t("meta.privacyPolicy.content")
        },
        {
          name: 'keywords',
          content: i18n.global.t("meta.privacyPolicy.keywords")
        }
      ],
    }
  },
  {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/legal/TermsOfUse.vue'),
    meta: {
      title: i18n.global.t("meta.termsOfUse.title"),
      metaTags: [
        {
          name: i18n.global.t("meta.termsOfUse.title"),
          content: i18n.global.t("meta.termsOfUse.content")
        },
        {
          property: 'og:description',
          content: i18n.global.t("meta.termsOfUse.content")
        },
        {
          property: 'description',
          content: i18n.global.t("meta.termsOfUse.content")
        },
        {
          name: 'keywords',
          content: i18n.global.t("meta.termsOfUse.keywords")
        }
      ],
    }

  },
  {
    path: '/investor-area',
    name: 'Investor Area',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Investment.vue'),
    meta: {
      title: i18n.global.t("meta.investment.title"),
      metaTags: [
        {
          name: i18n.global.t("meta.investment.title"),
          content: i18n.global.t("meta.investment.content")
        },
        {
          property: 'og:description',
          content: i18n.global.t("meta.investment.metaDescription")
        },
        {
          property: 'description',
          content: i18n.global.t("meta.investment.metaDescription")
        },
        {
          name: 'keywords',
          content: i18n.global.t("meta.investment.keywords")
        }
      ],
    }
  },
  {
    path: '/dynamic-1010',
    name: 'dynamic-1010',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/dynamic/Dynamic1010.vue'),
    meta: {
      title: i18n.global.t("meta.ceo.martinWeigl.title"),
      metaTags: [
        {
          name: i18n.global.t("meta.ceo.martinWeigl.title"),
          content: i18n.global.t("meta.ceo.martinWeigl.content")
        },
        {
          property: 'og:description',
          content: i18n.global.t("meta.ceo.martinWeigl.content")
        },
        {
          property: 'description',
          content: i18n.global.t("meta.ceo.martinWeigl.content")
        },
        {
          name: 'keywords',
          content: i18n.global.t("meta.ceo.martinWeigl.keywords")
        }
      ],
    }
  },
  {
    path: '/dynamic-0101',
    name: 'dynamic-0101',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/dynamic/Dynamic0101.vue'),
    meta: {
      title: i18n.global.t("meta.ceo.joachimKazianschuetz.title"),
      metaTags: [
        {
          name: i18n.global.t("meta.ceo.joachimKazianschuetz.title"),
          content: i18n.global.t("meta.ceo.joachimKazianschuetz.content")
        },
        {
          property: 'og:description',
          content: i18n.global.t("meta.ceo.joachimKazianschuetz.content")
        },
        {
          property: 'description',
          content: i18n.global.t("meta.ceo.joachimKazianschuetz.content")
        },
        {
          name: 'keywords',
          content: i18n.global.t("meta.ceo.joachimKazianschuetz.keywords")
        }
      ],
    }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function addOrReplaceMetaTag(name: string, content: string) {
  let meta: HTMLMetaElement | any = document.querySelector(
      'meta[name="' + name + '"]');
  if (meta) {
    meta.setAttribute('content', content);
  } else {
    meta = document.createElement('meta');
    meta.name = name;
    meta.content = content;
    document.head.appendChild(meta);
  }
}

function addOrReplaceMetaProperty(property: string, content: string) {
  let meta: HTMLMetaElement | any = document.querySelector(
      'meta[property="' + property + '"]');
  if (meta) {
    meta.setAttribute('content', content);
  } else {
    meta = document.createElement('meta');
    meta.setAttribute('property', property);
    meta.setAttribute('content', content);
    document.head.appendChild(meta);
  }
}

router.beforeEach((to, from) => {
  window.scrollTo(0,0);

  let title = `${to.meta.title}`;
  if (title === 'undefined') {
    title = 'theconcept technologies';
  }
  document.title = title;

  const metaTags: Array<Record<any, any>> | any = to.meta['metaTags'];
  if (metaTags && (from.path !== to.path || from.path == '/' && to.path == '/')) {
    metaTags.forEach((tag: any) => {
      if (tag.name) {
        addOrReplaceMetaTag(tag.name, tag.content);
      } else if (tag.property) {
        addOrReplaceMetaProperty(tag.property, tag.content);
      }
    });
  }
})

export default router
