<template>
	<div class="-translate-x-1 -mt-40 pt-36 translate-y-1 bg-gradient-to-br from-blue-950 via-tc-bg to-tc-bg">

		<!-- Header section -->
		<header class="relative">
		</header>

		<main>
			<!-- Hero section -->
			<section class="grid order-last sm:grid-cols-2 pt-0 p-10 sm:pt-0 md:pt-0 lg:pt-0 sm:p-6 md:p-10 lg:p-20 justify-center items-center mx-auto">
				<!-- Left - Text -->
<!--				backdrop-filter backdrop-blur-3xl shadow-2xl -->
				<div id="Info1"
						class=" sm:justify-self-end rounded-3xl p-0 pl-0 sm:pl-12 md:pl-0 sm:p-8 lg:pl-0 lg:p-12 xl:p-4 xl:pr-16 2xl:pr-16 2xl:p-4 xl:pl-0 max-w-4xl items-center flex-wrap text-white  font-extrabold sm:leading-normal"
						data-aos="fade-in"
						data-aos-anchor="#Info"
				>
					<div class="justify-self-center p-2">
						<h1 class="text-3xl md:text-3xl xl:text-5xl font-extrabold leading-[1.3em!important]">
							{{ t('landing.HeaderInfo') }}
						</h1>
						<p class="text-gray-300 mt-2 text-md md:text-xl xl:text-3xl mb-8">{{ t('landing.Section1Info') }}</p>

<!--						<router-link alt="Contact Button" to="/Vision" class="btn btn-shadow cursor-pointer mt-6 no-underline flex items-center justify-center px-5 py-3 border border-transparent text-base font-extrabold rounded-3xl text-white bg-tc-green hover:bg-gray-500">-->
							<router-link
									to="/Vision"
									class="-mt-2 border-2 border-black btn btn-shadow max-w-[16em] text-white font-extrabold bg-top px-10 bg-tc-green">
								{{ t('landing.Section2Btn') }}
							</router-link>
					</div>

				</div>

<!--		TODO testing/-->

				<!-- Right - Solutions  -->
				<div class="relative">
					<div class="md:flex max-w-3xl mt-20 justify-left space-x-12 items-center">
						<div class="w-full overflow-visible">
							<SolutionsCarousel
                  :free-mode="!isMobileDevice" :allow-touch-move="!isMobileDevice"
									direction="vertical"
									main-css="carousel-vertical-carousel-mask"
									header-text=""
                  :class="['max-h-[30em] md:max-h-none', isMobileDevice ? '-z-10' : '']"
									:use-default-button-colors="false"
									:speed="100000"
									:use-combined-slides="true"
									:change-direction="false"
									template-css="mb-20"
									padding="pt-20 px-8 pb-5"
									margin="mb-6"
									swiper-slide-css="mx-auto space-y-4"
                  :small-images="true"
                  img-loading-type="lazy"
							></SolutionsCarousel>
						</div>
						<div class="hidden xl:block w-full overflow-visible">
							<SolutionsCarousel
									direction="vertical"
									main-css="carousel-vertical-carousel-mask"
									header-text=""
									:use-default-button-colors="false"
									:speed="450000"
									:use-combined-slides="true"
									:change-direction="true"
									template-css="mb-20"
									padding="pt-20 px-8 pb-5"
									margin="mb-6"
									swiper-slide-css="mx-auto space-y-4"
                  :small-images="true"
                  img-loading-type="lazy"
							></SolutionsCarousel>
						</div>
					</div>
				</div>
			</section>

			<!-- Metrics section -->
			<section class="p-0 mt-16 mb-20 sm:p-0 grid justify-center text-center">
				<div
						id="Numbers"
						class="grid grid-cols-1 font-extrabold -mb-20 max-w-7xl"
						data-aos="fade-in"
						data-aos-anchor="#Numbers"
				>
					<h2 class="text-white text-3xl leading-10 md:text-4xl 2xl:text-5xl md:my-20 mb-0 md:mb-0 rounded-3xl mx-4 m-0 p-6 md:p-8 shadow-2xl backdrop-filter backdrop-blur-lg ">{{ t('landing.MetricTitle') }}</h2>
					<div class="grid mt-16 lg:mt-4 grid-cols-2 sm:grid-cols-4 space-x-4 mb-28 w-full">
						<MetricItem
								v-for="i in 4"
								:key="i"
								:metric="t(`landing.Metric${i}`)"
								:detail="t(`landing.Metric${i}Detail`)"
						/>
					</div>
				</div>
			</section>

			<!-- Info section -->
			<section
					id="Info2"
					class="pt-28 pb-0 sm:pb-28 grid grid-cols-1 lg:grid-cols-2 lg:mx-6 mb-4 lg:mb-20 -mt-12 xl:mx-auto max-w-7xl"
					data-aos="fade-in"
					data-aos-anchor="#Info"
			>
				<div class="relative min-h-[20em] rounded-t-3xl m-6 mb-0 lg:m-0 lg:rounded-r-none lg:rounded-l-3xl overflow-hidden lg:border-2 lg:border-white ">
					<kinesis-container
							class="absolute inset-0 h-full"
							event="scroll"
							:perspective="1000"
							:style="kinesisContainerLeftStyle"
					>
						<kinesis-element
								alt="tct"
								src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/landing-3.webp"
								:strength="10"
								tag="img"
								type="scaleX"
								class="w-full h-full object-cover"
						/>
					</kinesis-container>
					<div class="relative mx-12 my-20 md:my-40 font-extrabold text-left">
						<h2 class="text-white text-3xl pb-4">{{ t('landing.Section2Title') }}</h2>
						<p class="text-gray-300">{{ t('landing.Section2Info') }}</p>
					</div>
				</div>

				<div class="bg-tc-bglight rounded-b-3xl m-6 mt-0 lg:m-0 lg:rounded-r-3xl lg:rounded-l-none lg:border-2 lg:border-white lg:border-l text-justify"><div class="p-6 lg:p-12 text-gray-300">
						<p>{{ t('landing.Section2Detail1') }}</p>
						<p class="mt-4">{{ t('landing.Section2Detail2') }}</p>


						<router-link to="/Vision" class="btn cursor-pointer mt-6 no-underline flex items-center justify-center px-5 py-3 border border-transparent text-base font-extrabold rounded-3xl text-white bg-tc-green hover:bg-gray-500">
							{{ t('landing.Section2Btn') }}
						</router-link>
					</div>
				</div>
			</section>

			<!-- Partners section -->
			<section
					id="Info2"
					class="grid grid-cols-1 font-extrabold sm:mx-16 md:mx-auto max-w-7xl mt-56 sm:-mt-32 mb-20"
					data-aos="fade-in"
					data-aos-anchor="#Info2"
			>

				<h2 class="sm:mt-44 sm:mb-20 text-white text-center text-4xl 2xl:text-5xl">{{ t('landing.TitleWorkUtils') }}</h2>

				<WorkWithSwiper class="my-20"></WorkWithSwiper>
			</section>

			<!-- Contact section -->
			<section
					id="Info4"
					class="pt-28 pb-0 lg:pb-28 grid grid-cols-1 lg:grid-cols-2 lg:mx-6 mb-4 lg:mb-20 -mt-12 xl:mx-auto max-w-7xl"
					data-aos="fade-in"
          :data-aos-offset="isMobileDevice ? '-600' : '-400'"
					data-aos-anchor="#Info5">
				<div class="relative min-h-[20em] rounded-t-3xl m-6 mb-0 lg:m-0 lg:rounded-r-none lg:rounded-l-3xl overflow-hidden lg:border-2 lg:border-white ">
					<!-- Kinesis Container (bottom layer) -->
					<kinesis-container
							class="absolute inset-0 h-full"
							event="scroll"
							:perspective="1000"
							:style="kinesisContainerStyle"
					>
						<kinesis-element
								alt="tct"
								src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/landing-5.webp"
								:strength="10"
								tag="img"
								type="scaleX"
								class="w-full h-full object-cover"
						/>
					</kinesis-container>

					<!-- ParticleOrbAnimation (middle layer) -->
					<div class="absolute inset-0 w-full h-full">
						<particle-orb-animation />
					</div>

					<!-- Landing Section Title (top layer) -->
					<div class="relative z-10 top-[31%] sm:top-[42%] font-extrabold text-center">
						<h2 class="text-white text-3xl px-4 md:mx-20 leading-10">
							{{ t('landing.Section3Title') }}
						</h2>
					</div>
				</div>
				<div class="bg-tc-bglight rounded-b-3xl m-6 mt-0 lg:m-0 lg:rounded-r-3xl lg:rounded-l-none lg:border-2 lg:border-white lg:border-l text-justify">
					<div class="p-6 lg:p-12 text-gray-300">
						<p>{{ t('landing.Section3Detail1') }}</p>
						<p class="mt-4">{{ t('landing.Section3Detail2') }}</p>
						<router-link alt="Contact Button" to="/Contact" class="btn cursor-pointer mt-6 no-underline flex items-center justify-center px-5 py-3 border border-transparent text-base font-extrabold rounded-3xl text-white bg-tc-green hover:bg-gray-500">
							{{ t('landing.Section3Btn') }}
						</router-link>
					</div>
				</div>
			</section>

			<!-- Call To Action section -->
			<section
					id="Info5"
					class="grid grid-cols-1 font-extrabold mx-6 sm:mx-16 md:mx-auto max-w-7xl mt-24 sm:-mt-32 mb-20"
					data-aos="fade-in"
					data-aos-anchor="#Info5"
			>

				<h2 class="sm:mt-56 sm:mb-20 text-white text-center text-4xl 2xl:text-5xl">{{ t('landing.TitleYourTurn') }}</h2>

				<GeneralServiceOpenWizzard id="get-offer-tct" class="pt-28 sm:pt-20 pb-0 sm:pb-28 grid sm:mx-6 -mt-12 xl:mx-auto max-w-7xl"></GeneralServiceOpenWizzard>

        <h2 class="pt-28 sm:pt-52 text-white text-center text-4xl 2xl:text-5xl">{{ t('fundingPage.header') }}</h2>

        <PromotionCard id="promotion-tct-austria" :title="$t('promotionBanner.aws.title')" :description="$t('promotionBanner.aws.description')"
                       :redirect-text="$t('promotionBanner.aws.buttonText')"
                       redirect-link="/funding"
                       class="sm:px-6 lg:px-8 mb-4 sm:mb-20"
                       bg-image="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/theconcept-technologies/public/images/assets/promotion/promotions_cp.jpg"></PromotionCard>

      </section>

   </main>
	</div>
</template>

<script setup>
import { computed } from 'vue';
import { KinesisContainer, KinesisElement } from 'vue-kinesis';
import ParticleOrbAnimation from "@/components/animations/ParticleOrbAnimation";
import MetricItem from "@/components/items/MetricItem.vue";
import useTranslation from "@/composable/translation/useTranslation";
import { useAOS } from "@/composable/custom/useAOS";
import SolutionsCarousel from "@/components/services/SolutionsCarousel.vue";
import WorkWithSwiper from "@/components/swiper/WorkWithSwiper.vue";
import GeneralServiceOpenWizzard from "@/components/services/GeneralServiceOpenWizzard.vue";
import PromotionCard from "@/components/promotions/PromotionCard.vue";
import useSystem from "@/composable/core/useSystem";

const { t } = useTranslation();

// Initialize AOS
useAOS();

const kinesisContainerStyle = computed(() => ({
	minWidth: 'fit-content',
	width: '',
	marginLeft: '',
	overflow: 'hidden'
}));

const kinesisContainerLeftStyle = computed(() => ({
	minWidth: 'fit-content',
	width: '',
	marginLeft: ''
}));

const isMobileDevice = useSystem().isMobileDevice();
</script>

<style scoped>
.card {
    transition: transform 0.3s ease;
}

.card:hover {
    transform: scale(1.05);
}

.btn-shadow {
    background: rgb(109, 163, 34);
    padding: 1rem;
    position: relative;
    height: 2em;
    width: 20em;
}

.btn-shadow::after {
    content: "";
    position: absolute;
    inset: 0.8em;
    background: linear-gradient(to bottom right, rgb(83, 188, 245), rgb(31, 32, 35));
    filter: blur(2em);
    z-index: -1;
}

</style>
