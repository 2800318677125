import axios, {AxiosRequestConfig} from 'axios';
// import {Buffer} from 'buffer';

/* eslint-disable */
class ResponseError extends Error {
	errorCode: any;
	errorMessage: any;

	constructor(errorCode: any, message: string | undefined) {
		super(message);
		this.name = this.constructor.name;
		if (message != null) {
			this.message = message;
		}
		this.errorCode = errorCode;
	}
}
/* eslint-enable */

const ApiService = {
	_requestInterceptor: 0,
	_401interceptor: 0,

	init(baseURL: string | undefined) {
		axios.defaults.baseURL = baseURL;
		this.setHeader();
		// this.getCsrfToken();
	},

	// setHeader() {
	// 	const username = process.env.VUE_APP_API_USERNAME;
	// 	const password = process.env.VUE_APP_API_PASSWORD;
	// 	const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')
	//
	// 	// axios.defaults.headers.common["Authorization"] = `Basic ${token}`;
	// 	axios.defaults.headers.common["x-api-key"] = this.getApiKey();
	// },

	//TODO login via Bearer Token
	setHeader() {
		axios.defaults.headers.common["Content-Type"] =  "application/json";
		axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
		axios.defaults.withCredentials = true;
		// axios.defaults.headers.common["Authorization"] =  null;
	},

	getCookie(name: any) {
		// Split cookie string and get all individual name=value pairs in an array
		const cookieArr = document.cookie.split(";");

		// Loop through the array elements
		for (let i = 0; i < cookieArr.length; i++) {
			const cookiePair = cookieArr[i].split("=");

			/* Removing whitespace at the beginning of the cookie name
			and compare it with the given string */
			if (name == cookiePair[0].trim()) {
				// Decode the cookie value and return
				return decodeURIComponent(cookiePair[1]);
			}
		}

		// Return null if not found
		return null;
	},

	getDefaultContentTypeHeader() {
		const csrf = this.getCookie('XSRF-TOKEN');

		return {
			// 'Content-Type': 'application/json',
			// Accept: 'application/json',
			"Authorization": null,
			withCredentials: true,
			'X-Requested-With': 'XMLHttpRequest',
			'XSRF-TOKEN': csrf,
			'X-XSRF-TOKEN': csrf,
			crossdomain: true
		}
	},

	getCsrfToken() {
		// const sanctumUrl = process.env.VUE_APP_API_DOMAIN + '/sanctum/csrf-cookie';
		const sanctumUrl = '/sanctum/csrf-cookie';
		return axios.get(sanctumUrl).then(response => {
				return response;
			},
			error => {
				// eslint-disable-next-line
				console.log("CSRF-Error", error);
			});
	},

	removeHeader() {
		axios.defaults.headers.common = {};
	},

	get(resource: string, config?: AxiosRequestConfig) {
		return axios.get(resource, config);
	},

	// eslint-disable-next-line
	async post(resource: string, data: any, headers?: any | null) {
		const sanctumUrl = '/sanctum/csrf-cookie';
		// eslint-disable-next-line
		return await axios.get(sanctumUrl).then(response => {
			if (headers) {
				return axios.post(resource, data, headers);
			} else {
				return axios.post(resource, data);
				// postHeaders = this.getDefaultContentTypeHeader();
			}
		});
	},

	// eslint-disable-next-line
	put(resource: string, data: any) {
		this.getCsrfToken();
		return axios.put(resource, data);
	},

	delete(resource: string) {
		this.getCsrfToken();
		return axios.delete(resource);
	},

	customRequest(data: AxiosRequestConfig) {
		return axios(data);
	},

	mountRequestInterceptor() {
		this._requestInterceptor = axios.interceptors.request.use(async config => {
			// const loading = await loadingController.create({
			// 	message: 'Please wait...'
			// });
			// await loading.present();

			return config;
		});
	},

	mount401Interceptor() {
		this._401interceptor = axios.interceptors.response.use(
			response => {
				//loading.dismiss().then(r => console.log(r));
				return response;
			},
			// async error => {
			function (error: any) {
				//loading.dismiss().then(r => console.log(r));
				if (
					error.response &&
					[401].includes(error.response.status)
				) {
					//TODO mabey we can refresh the token in a next step?
					// GreeveApiAuth.logout(false, true);
				} else if (error.response &&
					[419].includes(error.response.status)
				) {
					console.log("CSRF- ERROR", error.response);
				}
				throw error;
			}
		);
	},

	unmount401Interceptor() {
		axios.interceptors.response.eject(this._401interceptor);
	}
}

export default ApiService;