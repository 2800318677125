<template>
	<div class="animation-container">
		<div class="wrap">
			<div v-for="n in 300" :key="n" class="c"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ParticleOrbAnimation",
	components: {},
}
</script>

<style lang="scss" scoped>
$total: 300; // total particles
$orb-size: 5em;
$particle-size: 0.2em;
$time: 8s; // Reduced time for a quicker "breath"
$base-hue: 0; // change for diff colors (180 is nice)

.animation-container {
  width: 100%;
  height: 0;
  padding-bottom: 100%; // This creates a square aspect ratio
  overflow: hidden;
}

.wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  width: $orb-size * 2;
  height: $orb-size * 2;
  margin-left: -$orb-size;
  margin-top: -$orb-size;
  transform-style: preserve-3d;
  perspective: 1000px;
}

.c {
  position: absolute;
  width: $particle-size;
  height: $particle-size;
  border-radius: 50%;
  opacity: 0;
  top: 50%;
  left: 50%;
  margin-top: -$particle-size / 2;
  margin-left: -$particle-size / 2;
}

@for $i from 1 through $total {
  $z: (random(360) * 1deg); // random angle to rotateZ
  $y: (random(360) * 1deg); // random to rotateX
  $hue: ((40/$total * $i) + $base-hue); // set hue

  .c:nth-child(#{$i}) { // grab the nth particle
    animation: orbit#{$i} $time infinite ease-in-out;
    animation-delay: ($i * .01s);
    background-color: hsl(85, 66%, 48%)
  }

  @keyframes orbit#{$i} {
    0%, 100% {
      transform: rotateZ(-$z) rotateY($y) translateX($orb-size) rotateZ($z);
      opacity: 0;
    }
    25%, 75% {
      opacity: 0.8; // Peak opacity
    }
    50% {
      transform: rotateZ(-$z) rotateY($y) translateX($orb-size * 2) rotateZ($z);
      opacity: 1; // Full opacity at the furthest point
    }
  }
}
</style>
