import {AbstractWizzardService} from "@/theconcept/wizzard/base/abstract_wizzard_service";
import {WizzardType} from "@/theconcept/wizzard/base/wizzard.interface";

export class WizzardWebDevelopment extends AbstractWizzardService {
	getType(): WizzardType {
		return WizzardType.WEB_DEVELOPMENT;
	}

	getWizzardColor(): string {
		return 'bg-tc-webBtn';
	}

	getWizzardPrimaryTextColor(): string {
		return 'text-tc-webBtn';
	}
}