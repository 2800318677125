import {AbstractWizzardService} from "@/theconcept/wizzard/base/abstract_wizzard_service";
import {WizzardType} from "@/theconcept/wizzard/base/wizzard.interface";

export class WizzardProductDevelopment extends AbstractWizzardService {
	getType(): WizzardType {
		return WizzardType.PRODUCT_DEVELOPMENT;
	}

	getWizzardColor(): string {
		return 'bg-tc-productBtn';
	}

	getWizzardPrimaryTextColor(): string {
		return 'text-tc-productBtn';
	}
}