
import {defineComponent, onMounted, ref, toRef, watch} from 'vue';
import useTranslation from '@/composable/translation/useTranslation';
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue';
import {XMarkIcon, ExclamationCircleIcon} from '@heroicons/vue/24/outline';
import WizzardComponent from '@/components/inquiry/wizzard/WizzardComponent.vue';
import {WizzardType} from '@/theconcept/wizzard/base/wizzard.interface';
import useWizzardFactory from '@/composable/wizzard/useWizzardFactory';

export interface ScriptInterface {
	src: string;
	type?: string;
	arguments?: any;
	content?: string;
}

export default defineComponent({
	name: 'WizzardDialogOverlay',
	components: {
		WizzardComponent,
    XMarkIcon, ExclamationCircleIcon,
		Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
	},
	props: {
		title: {type: String},
		description: {type: String},
		confirmButtonName: {type: String, default: 'Confirm'},
		icon: {type: Object, default: ExclamationCircleIcon},
    showLogo: {type: Boolean, default: true},
		cancelButtonName: {type: String, default: 'Cancel'},
		visible: {type: Boolean, default: false},
		type: {type: String, default: 'alert'},
		wizzardType: {
			type: String as () => WizzardType,
			default: WizzardType.WEB_DEVELOPMENT,
		},
		allowBodyDismiss: {
			type: Boolean,
			default: false,
		},
		showDismissButton: {
			type: Boolean,
			default: true,
		},
		showLoadingAnimationOnConfirm: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['confirm', 'cancelConfirm', 'close'],
	setup(props, {emit}) {
		const dialogTitle = toRef(props, 'title');
		const dialogDescription = toRef(props, 'description');
		const visible = toRef(props, 'visible');
		const open = ref(visible.value);
		const {t} = useTranslation();
		const currentWizzard = ref();
		const {getWizzardFactory} = useWizzardFactory();

		function toggleDialog() {
			open.value = !open.value;
		}

		const cancelDialogOutsideClick = () => {
			if (props.allowBodyDismiss) {
				open.value = false;
				emit('cancelConfirm');
			}
		};

		const cancelDialog = () => {
			open.value = false;
			emit('cancelConfirm');
		};

		function confirm() {
			emit('confirm');
		}

    function resetWizzard(closeDialog = false) {
      currentWizzard.value = getWizzardFactory().createWizzardByType(props.wizzardType);
      if (closeDialog) {
        cancelDialog();
      }
    }

		watch(() => props.visible, (newValue: boolean) => {
			open.value = newValue;
		});

		onMounted(() => {
      resetWizzard();
		});

		return {
			dialogTitle,
			dialogDescription,
			open,
			toggleDialog,
			confirm,
			cancelDialog,
			cancelDialogOutsideClick,
			t,
			currentWizzard,
      resetWizzard,
		};
	},
});
