import {useI18n} from 'vue-i18n';

export default function useTranslation() {
	const {t, locale, messages} = useI18n({
		// inheritLocale: true,
		useScope: 'global',
	});

	return {
		t, locale, messages
	}
}
