export interface StepSummaryDataInterface {
	step: number;
	title: string;
	description: string;
	image?: string;
	additionalInfo?: string;
}

export class WizzardSummary {
	summaryTitle = '';
	summaryData: Array<StepSummaryDataInterface> = [];

	getSummaryTitle(): string {
		return this.summaryTitle;
	}

	addSummaryData(summaryData: StepSummaryDataInterface)
	{
		this.removeSummaryData(summaryData.step);
		this.summaryData.push(summaryData);
	}

	removeSummaryData(step: number) {
		this.summaryData = this.summaryData.filter(s => s.step !== step);
		this.summaryData.sort((a, b) => a.step - b.step);
	}

	getSummaryData()  : Array<StepSummaryDataInterface>
	{
		this.summaryData.sort((a, b) => a.step - b.step);
		return this.summaryData;
	}
}