import {WizzardSummary} from '@/theconcept/wizzard/base/wizzard_summary';
import {CheckBoxItem} from "@/components/inputs/MultiCheckBox.vue";

export const enum WizzardType {
	GENERAL_OFFER = 'general_offer',
	WEB_DEVELOPMENT = 'web_development',
	AI_INTEGRATION = 'ai_integration',
	API = 'api',
	AR_VR_DEVELOPMENT = 'ar_vr_development',
	PRODUCT_DEVELOPMENT = 'product_development',
	DESIGN = 'design',
	BRANDING = 'branding',
}

export const WizzardTypeValues = [
	WizzardType.WEB_DEVELOPMENT,
	WizzardType.AI_INTEGRATION,
	WizzardType.API,
	WizzardType.AR_VR_DEVELOPMENT,
	WizzardType.PRODUCT_DEVELOPMENT,
	WizzardType.DESIGN,
	WizzardType.BRANDING,
]

export const WizzardPriorityTypes = {
	speed: 'speed',
	quality: 'quality',
	budget: 'budget',
}

export const WizzardPriorityTypesValues = [
	WizzardPriorityTypes.speed,
	WizzardPriorityTypes.quality,
	WizzardPriorityTypes.budget,
]

export const WizzardAiProjectTypes = [
	'ai_consulting',
	'machine_learning_models',
	'natural_language_processing',
	'computer_vision',
	'data_analysis_ai',
	'automation',
	'chatbots_virtual_assistants',
	'ai_custom_solutions',
	'other',
] as const;

export const WizzardWebDevelopmentProjectTypes = [
	'website',
	'online_shop',
	'web_application',
	'mobile_application',
	'custom_application',
	'blog',
	'portfolio',
	'other',
] as const;

export const WizzardArVrDevelopmentProjectTypes = [
	'ar_experience',
	'vr_experience',
	'mixed_reality',
	'immersive_training',
	'virtual_tours',
	'interactive_gaming',
	'custom_ar_vr_solutions',
	'other',
] as const;

export const WizzardApiProjectTypes = [
	'rest_api',
	'graphql_api',
	'third_party_integration',
	'api_security',
	'microservices',
	'api_management',
	'custom_api',
	'other',
] as const;

export const WizzardDesignProjectTypes = [
	'ux_ui_design',
	'product_design',
	'graphic_design',
	'branding',
	'illustration',
	'interaction_design',
	'prototyping_wireframing',
	'custom_design_solution',
	'other',
] as const;

export const WizzardBrandingProjectTypes = [
	'brand_strategy',
	'logo_design',
	'visual_identity',
	'brand_guidelines',
	'rebranding',
	'brand_messaging',
	'packaging_design',
	'custom_branding_solution',
	'other',
] as const;

export const WizzardProductDevelopmentProjectTypes = [
	'concept_development',
	'prototyping',
	'product_design',
	'mvp_development',
	'product_testing',
	'product_launch',
	'custom_product_solution',
	'other',
] as const;

export type ProjectTypes =
	typeof WizzardWebDevelopmentProjectTypes |
	typeof WizzardAiProjectTypes |
	typeof WizzardArVrDevelopmentProjectTypes |
	typeof WizzardApiProjectTypes |
	typeof WizzardProductDevelopmentProjectTypes |
	typeof WizzardBrandingProjectTypes |
	typeof WizzardDesignProjectTypes;

export const WebDevelopmentServices = [
	'website_design_development',
	'ux_ui_design',
	'e_commerce_integration',
	'content_management_system',
	'seo_optimization',
	'email_marketing_setup',
	'ads_management',
	'web_analytics',
	'maintenance_and_support',
	'other',
] as const;

export const AiIntegrationServices = [
	'ai_consulting',
	'machine_learning_models',
	'natural_language_processing',
	'computer_vision',
	'data_analysis_ai',
	'automation',
	'chatbots_virtual_assistants',
	'ai_custom_solutions',
	'other',
] as const;

export const ArVrDevelopmentServices = [
	'ar_development',
	'vr_development',
	'mr_development',
	'3d_modeling',
	'environment_design',
	'ar_vr_consulting',
	'user_experience_design',
	'hardware_integration',
	'maintenance_and_support',
	'other',
] as const;

export const ApiServices = [
	'api_design_architecture',
	'rest_api_development',
	'graphql_api_development',
	'third_party_api_integration',
	'api_security_implementation',
	'microservices_development',
	'api_management_solutions',
	'api_documentation',
	'maintenance_and_support',
	'other',
] as const;

export const DesignServices = [
	'ux_ui_design',
	'product_design',
	'graphic_design',
	'branding_identity',
	'illustration_services',
	'interaction_design',
	'prototyping_wireframing',
	'design_consulting',
	'maintenance_and_support',
	'other',
] as const;

export const BrandingServices = [
	'brand_strategy',
	'logo_design',
	'visual_identity_design',
	'brand_guidelines_creation',
	'rebranding_services',
	'brand_messaging_development',
	'packaging_design',
	'brand_consulting',
	'maintenance_and_support',
	'other',
] as const;

export const ProductDevelopmentServices = [
	'concept_development',
	'prototyping_services',
	'product_design',
	'mvp_development',
	'product_testing',
	'product_launch',
	'product_optimization',
	'maintenance_and_support',
	'other',
] as const;

export type ServiceTypeArray =
	typeof WebDevelopmentServices |
	typeof AiIntegrationServices |
	typeof ArVrDevelopmentServices |
	typeof ApiServices |
	typeof DesignServices |
	typeof BrandingServices |
	typeof ProductDevelopmentServices;

export const enum WizzardState {
	NEW = 'new',
	ERROR = 'error',
	READY = 'ready',
	DONE = 'done',
}

export interface WizzardInterface {
	name: string | undefined;
	wizzardSummary: WizzardSummary | undefined;
	_state: WizzardState;
	currentStep: number;
}

export interface WizzardAdditionalDataInterface {
	additionalData: any;
	companyDetails: any;
}

export interface WizzardProjectTypesInterface {
	projectTypes: Array<string>;
}

export interface WizzardPriorityTypesInterface {
	priorityTypes: Array<string>;
}

export interface WizzardServicesInterface {
	services: CheckBoxItem[];
}