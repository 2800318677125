import {WizzardType} from '@/theconcept/wizzard/base/wizzard.interface';
import {
	WizzardWebDevelopment
} from '@/theconcept/wizzard/web_development/wizzard_web_development';
import {WizzardAiIntegration} from "@/theconcept/wizzard/ai_integration/wizzard_ai_integration";
import {WizzardApi} from "@/theconcept/wizzard/api/wizzard_api";
import {WizzardArVrDevelopment} from "@/theconcept/wizzard/ar_vr_development/wizzard_ar_vr_development";
import {WizzardDesign} from "@/theconcept/wizzard/design/wizzard_design";
import {WizzardBranding} from "@/theconcept/wizzard/branding/wizzard_branding";
import {WizzardProductDevelopment} from "@/theconcept/wizzard/product_development/wizzard_product_development";
import {WizzardGeneralOffer} from "@/theconcept/wizzard/general_offer/wizzard_general_offer";

export class WizzardFactory {
	createWizzardByType(type: WizzardType) {
		switch (type) {
			case WizzardType.GENERAL_OFFER:
				return new WizzardGeneralOffer();
			case WizzardType.WEB_DEVELOPMENT:
				return new WizzardWebDevelopment();
			case WizzardType.AI_INTEGRATION:
				return new WizzardAiIntegration();
			case WizzardType.API:
				return new WizzardApi();
			case WizzardType.AR_VR_DEVELOPMENT:
				return new WizzardArVrDevelopment();
			case WizzardType.DESIGN:
				return new WizzardDesign();
			case WizzardType.BRANDING:
				return new WizzardBranding();
			case WizzardType.PRODUCT_DEVELOPMENT:
				return new WizzardProductDevelopment();
			default:
				throw new Error(`Unknown wizzard type ${type}`);
		}
	}
}