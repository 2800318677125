
import {defineComponent, ref, onMounted, onUnmounted} from 'vue';
import LanguageSelector from '@/components/Locale/LanguageSelector.vue';
import {
  HeartIcon,
  LightBulbIcon,
  BuildingOfficeIcon,
  XMarkIcon,
  Bars3Icon,
} from '@heroicons/vue/24/outline';
import useTranslation from '@/composable/translation/useTranslation';
import useCustomStore from "@/composable/custom/useCustomStore";

export default defineComponent({
  name: 'Navbar',
  components: {
    LanguageSelector,
    HeartIcon,
    LightBulbIcon,
    BuildingOfficeIcon,
    Bars3Icon,
    XMarkIcon,
  },
  props: {
    CompanyName: {
      type: String,
      default: 'theconcept',
    },
    CompanySureName: {
      type: String,
      default: 'technologies',
    },
  },
  setup() {
    const {t} = useTranslation();
    const showMenu = ref(false);
    const {getHighlightColor, setHighlightColor} = useCustomStore();

    const handleScroll = () => {
      // Update navbarColor based on global highlightColor
      if (window.scrollY === 0) {
        // setIsScrolled(false);
        setHighlightColor('bg-transparent');
      } else {
        // setIsScrolled(true);
      }
    };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
      handleScroll(); // Initialize on load
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    function open() {
      showMenu.value = true;
    }

    function close() {
      showMenu.value = false;
    }

    return {
      showMenu,
      open,
      close,
      t,
      getHighlightColor,
    };
  },
});
